const en = {
  menu: {
    users: 'Users',
    roles: 'Roles',
    idProvider: 'Identity provider'
  },
  common: {
    user: 'User',
    user_plural: 'Users',
    userSource: 'Source',
    userName: 'Name',
    userEmail: 'Email',
    userPassword: "Password",
    userPasswordRepeat: "Repeat password",
    userIntegrationLogin: 'Integration Username',
    userIntegrationSystemName: 'System name (Integration Login)',
    userIntegrationUserName: 'User name (Integration Login)',
    userActiveFlag: 'Active',
    userInactiveFlag: 'Inactive',
    userActiveTrue: 'Yes',
    userActiveFalse: 'No',

    useApiSecret: 'Use generated API Secret instead of password',
    apiSecretLastChange: 'API Secret last changed',

    role: 'Role',
    role_plural: 'Roles',
    roleName: 'Name',
    roleDescription: 'Description',
    rolePermissions: 'Permissions'
  },
  userList: {
    header: '$t(userManagement:common.user_plural)',
    buttonNew: 'New $t(userManagement:common.user)'
  },
  userSearch: {
    searchPlaceholder: 'Search by {{fields}}',
    checkboxActiveAndInactiveUsers: 'Active and inactive users',
    checkboxOnlyActiveUsers: 'Only active users',
    checkboxOnlyInactiveUsers: 'Only inactive users',
    filterPlaceholder: 'Filter search by...',
    dropdownNameText: 'Name',
    dropdownEmailText: 'Email',
    dropdownUsernameText: 'User name',
    dropdownIntegrationLoginText: 'System name',
    dropdownRoleText: 'Roles'
  },
  userView: {
    buttonEdit: 'Edit',
    buttonDelete: 'Delete',

    deleteConfirmText: 'Do you really want to delete the user {{name}}?',
    deletedUser: 'User deleted',
    deleteError: {
      unknown: 'Unexpected error deleting user',
      cannotDeleteSelf: 'You can not delete your own user account.'
    }
  },
  userEdit: {
    titleNewUser: 'New $t(userManagement:common.user)',
    buttonCancel: 'Cancel',
    buttonSave: 'Save',
    searchPlaceholder: 'Search role by name or description',
    error: {
      passwordTooShort: 'At least 8 characters are required for the password',
      unknown: 'Error while saving',
      emailAlreadyInUse: 'The email address is already in use for another user.',
      noResponse: 'No response from server. Please check your connection.',
      emailOrSystemNameRequired: 'Either email address or integration login is required',

    },
    buttonAddRoles: 'Add roles',
    roleModal: {
      header: 'Add roles',
      emptyHeader: 'No roles found',
      emptyHeader2: 'No search results',
      emptyText: 'Only roles which are not yet assigned to this user are shown.',
      buttonCancel: 'Cancel',
      buttonAdd: 'Add'
    },
    apiSecretModal: {
      createApiSecret: 'Create API Secret',
      changeApiSecret: 'Refresh API Secret',

      changeSecretText: 'Do you really want to create a new API Secret for this user? The current API Secret will become invalid. ',
      createNewSecretText: 'Do you want to generate an API Secret for this user? This can be used in place of a password when authenticating at the API.',

      confirmPasswordText: 'As this is your own user account, please confirm the change by providing your password.',
      currentPassword: 'Your password',

      buttonClose: 'Close',
      createdText: 'The API Secret is only displayed once. Please store it in a safe place because as soon as you close this window you will not be able to retrieve it again.'
    },
    passwordModal: {
      changePassword: 'Change password',
      currentPassword: 'Current password',
      password: 'New password',
      newPasswordConfirmation: 'Confirm new password',
      buttonCancel: 'Cancel',
      successMessage: 'Password changed',
      error: {
        noResponse: 'No response from server. Please check your network connection and try again.',
        badRequest: 'Password change request is invalid',
        unknown: 'Unknown error'
      }
    }
  },
  roleList: {
    header: '$t(userManagement:common.role_plural)',
    subText: 'Roles define a set of permissions for entities and buckets. Users can be assigned to multiple roles.',
    buttonNew: 'New $t(userManagement:common.role)',
  },
  roleSearch: {
    searchPlaceholder: "Search by {{fields}}",
    filterPlaceholder: 'Suche filtern nach...',
    dropdownNameText: 'Name',
    dropdownDescriptionText: 'Description',
    dropdownEntityText: 'Entity',
    dropdownBucketText: 'Bucket',
  },
  roleView: {
    buttonEdit: 'Edit',
    buttonDelete: 'Delete',
    roleInfoSection: 'General',
    userListSection: 'Users assigned to this role',

    permissionAll: 'All',
    permissionBucket: 'Bucket',  // todo create common translations with terms used on all pages
    permissionBucket_plural: 'Buckets',
    permissionEntity: 'Entity',
    permissionEntity_plural: 'Entities',

    deleteConfirmText: 'Do you really want to delete the role "{{name}}"?',
    deletedRole: 'Deleted role.',
    deleteRoleError: {
      unknown: 'Could not delete role.',
      currentUserAssigned: 'Could not delete role, because your user is assigned to it.'
    }
  },
  roleEdit: {
    titleNewRole: 'New $t(userManagement:common.role)',
    buttonCancel: 'Cancel',
    buttonSave: 'Save',

    saveRoleError: {
      generic: 'Role could not be saved.',
      badRequest: 'Invalid role details or permissions.',
      nameInUse: 'Role name is already in use.'
    }
  },

  // copied from CAS MON
  identityProvider: {
    delete_button: {
      success_msg: 'ID provider deleted',
      error_msg: 'Couldn\'t delete ID provider',
      not_found_error: 'ID provider no longer exists, please refresh the page.',
      has_user_error: 'ID provider cannot be deleted because these are already assigned to users',
      delete_button: 'Delete',
      confirm_delete: 'Yes, remove it',
      deleted_id_provider1: 'Do you want to remove the ID provider',
      deleted_id_provider2: '?',
      deleted_id_provider_info: 'If you only want to restrict the registration temporarily, you can deactivate the ID provider instead.'
    },
    editPage: {
      save_config_error: 'Failed to save the configuration',
      invalid_config_error: 'Invalid configuration - please check your input.',
      msg_header: 'Identity provider could not be loaded'
    },
    form: {
      connection_header: 'Connection',
      provider_id_help_text: 'Cannot be changed after creation. Used for callback URLs. Only alphanumeric characters and hyphens allowed.',
      active_label: 'Active',
      active_help_text: 'Only active identity providers can be used for login',
      name_help_text: 'Display name of the provider',
      representation_header: 'Presentation',
      client_secret_input: '(unchanged)',
      display_order_label: 'Display Order',
      display_order_help_text: 'Identity providers are sorted in ascending order according to this value on the login screen',
      color_label: 'Background Color',
      color_help_text: 'RGB HEX format. For example #2185d0',
      text_color_label: 'Text Color',
      button_preview_header: 'Button Preview',
      cancel_button: 'Cancel',
      save_button: 'Save'
    },
    list_page: {
      identity_provider_header: 'Identity provider',
      paragraph: 'Configure integrations with OpenID Connect identity providers which users can use to log into CAS RDH',
      add_id_provider: 'Add ID provider',
      table_header_active: 'Active',
      table_header_type: 'Type',
      table_header_display_order: 'Display order',
      call_data_error: 'Error fetching list',
      update_button: 'Update',
      config_error: 'No identity provider configured',
      no_item: 'No',
      yes_item: 'Yes'
    },
    new_page: {
      save_config_error: 'Failed to save the configuration',
      already_exist_error: 'An identity provider with the provider ID already exists',
      invalid_config_error: 'Invalid configuration - please check your input.',
      add_id_provider: 'Add ID provider'
    },
    test_connection: {
      error: {
        invalid_url: 'Invalid URL',
        invalid_protocol: 'URL must start with http: // or https: //',
        no_client_id: 'Client ID must be specified',
        unknown: 'Unknown error',
        internal: 'Unknown internal error'
      },
      modal_header: 'OpenID Connect connection test',
      test_connection: 'Test connection ...',
      error_msg_header: 'Test failed',
      success_msg_header: 'Test passed',
      validation_error_msg: 'Only the accessibility of the OpenID Connect metadata endpoint is checked. The validity of Client ID and client Secret is not validated in this step.',
      close_button: 'Close',
      test_availability_button: 'Test availability'
    },
    view: {
      error_header: 'Identity provider could not be loaded',
      edit_button: 'Edit',
      connection_header: 'Connection',
      active_label: 'Active',
      button_preview_header: 'Button Preview'
    },
    input_image: {
      size_error: 'File exceeds maximum size of ',
      format_error: 'Illegal format'
    },
    without: 'without'
  }
};

const de = {
  menu: {
    users: 'Benutzer',
    roles: 'Rollen',
    idProvider: 'Identity Provider'
  },
  common: {
    user: 'Benutzer',
    user_plural: 'Benutzer',
    userSource: 'Quelle',
    userName: 'Name',
    userEmail: 'E-Mail',
    userIntegrationLogin: 'Integration Username',
    userIntegrationSystemName: 'System-Name (Integration Login)',
    userIntegrationUserName: 'User-Name (Integration Login)',
    userPassword: "Passwort",
    userPasswordRepeat: "Passwort wiederholen",
    userActiveFlag: 'Aktiv',
    userInactiveFlag: 'Inaktiv',
    userActiveTrue: 'Ja',
    userActiveFalse: 'Nein',

    useApiSecret: 'Verwende generiertes API Secret anstatt Passwort',
    apiSecretLastChange: 'API Secret zuletzt geändert',

    role: 'Rolle',
    role_plural: 'Rollen',
    roleName: 'Name',
    roleDescription: 'Beschreibung',
    rolePermissions: 'Berechtigungen'
  },
  userList: {
    header: '$t(userManagement:common.user_plural)',
    buttonNew: 'Neuer $t(userManagement:common.user)'
  },
  userSearch: {
    searchPlaceholder: 'Suche nach {{fields}}',
    checkboxActiveAndInactiveUsers: 'Aktive and inaktive Benutzer',
    checkboxOnlyActiveUsers: 'Nur aktive Benutzer',
    checkboxOnlyInactiveUsers: 'Nur inaktive Benutzer',
    filterPlaceholder: 'Suche filtern nach...',
    dropdownNameText: 'Name',
    dropdownEmailText: 'E-Mail',
    dropdownUsernameText: 'User-Name',
    dropdownIntegrationLoginText: 'System-Name',
    dropdownRoleText: 'Rollen'
  },
  userView: {
    buttonEdit: 'Bearbeiten',
    buttonDelete: 'Löschen',

    deleteConfirmText: 'Möchten Sie den Benutzer {{name}} wirklich löschen?',
    deletedUser: 'Benutzer gelöscht.',
    deleteError: {
      unknown: 'Unerwarteter Fehler beim Löschen des Benutzers.',
      cannotDeleteSelf: 'Sie können nicht Ihren eigenen RDH Benutzer löschen.'
    }
  },
  userEdit: {
    titleNewUser: 'Neuer $t(userManagement:common.user)',
    buttonCancel: 'Abbrechen',
    buttonSave: 'Speichern',
    searchPlaceholder: 'Suche Rolle nach Namen oder Beschreibung',
    error: {
      passwordTooShort: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
      unknown: 'Fehler beim Speichern',
      emailAlreadyInUse: 'Die E-Mail Adresse wird bereits für einen anderen Benutzer verwendet.',
      noResponse: 'Keine Antwort vom Server',
      emailOrSystemNameRequired: 'Entweder E-Mail Adresse oder System/User-Name muss angegeben werden.',
    },
    buttonAddRoles: 'Rollen hinzufügen',
    roleModal: {
      header: 'Rollen hinzufügen',
      emptyHeader: 'Keine Rollen gefunden',
      emptyHeader2: 'Keine Suchergebnisse',
      emptyText: 'Es werden nur Rollen angezeigt, die diesem Benutzer noch nicht zugewiesen sind.',
      buttonCancel: 'Abbrechen',
      buttonAdd: 'Hinzufügen'
    },
    apiSecretModal: {
      createApiSecret: 'API Secret erstellen',
      changeApiSecret: 'API Secret aktualisieren',

      changeSecretText: 'Möchten Sie wirklich ein neues API Secret für diesen Benutzer erstellen? Das aktuelle API Secret wird damit ungültig. ',
      createNewSecretText: 'Möchten Sie ein API Secret für diesen User generieren? Dieses kann zur Authentifizierung an der API anstelle eines Passwortes verwendet werden.',

      confirmPasswordText: 'Da dies Ihr eigener User ist, bestätigen Sie diese Aktion bitte mit Ihrem Passwort.',
      currentPassword: 'Passwort',

      buttonClose: 'Schließen',
      createdText: 'Das API Secret wird nur einmal angezeigt. Bitte bewahren Sie es an einem sicheren Ort auf, da Sie es nicht mehr einsehen können, sobald Sie dieses Fenster schließen.'
    },
    passwordModal: {
      changePassword: 'Passwort ändern',
      currentPassword: 'Derzeitiges Passwort',
      password: 'Neues Passwort',
      newPasswordConfirmation: 'Neues Passwort bestätigen',
      buttonCancel: 'Abbrechen',

      successMessage: 'Passwort geändert',
      error: {
        noResponse: 'Keine Antwort vom Server. Prüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.',
        badRequest: 'Passwortänderung ist ungültig, prüfen Sie Ihre Eingaben.',
        unknown: 'Unbekannter Fehler'
      }
    }
  },
  roleList: {
    header: '$t(userManagement:common.role_plural)',
    subText: 'Rollen definieren ein Set von Berechtigungen, welches anschließend Benutzern zugewiesen werden kann',
    buttonNew: 'Neue $t(userManagement:common.role)',
  },
  roleSearch: {
    searchPlaceholder: 'Suche nach {{fields}}',
    filterPlaceholder: 'Suche filtern nach...',
    dropdownNameText: 'Name',
    dropdownDescriptionText: 'Beschreibung',
    dropdownEntityText: 'Entität',
    dropdownBucketText: 'Bucket',
  },
  roleView: {
    buttonEdit: 'Bearbeiten',
    buttonDelete: 'Löschen',
    roleInfoSection: 'Allgemein',
    userListSection: 'Benutzer mit dieser Rolle',

    permissionAll: 'Alle',
    permissionBucket: 'Bucket',  // todo create common translations with terms used on all pages
    permissionBucket_plural: 'Buckets',
    permissionEntity: 'Entität',
    permissionEntity_plural: 'Entitäten',

    deleteConfirmText: 'Möchten Sie die Rolle "{{name}}" wirklich löschen?',
    deletedRole: 'Rolle gelöscht',
    deleteRoleError: {
      unknown: 'Rolle konnte nicht gelöscht werden',
      currentUserAssigned: 'Rolle konnte nicht gelöscht werden, da Sie derzeit der Rolle zugeordnet sind.'
    }
  },
  roleEdit: {
    titleNewRole: 'Neue $t(userManagement:common.role)',
    buttonCancel: 'Abbrechen',
    buttonSave: 'Speichern',

    saveRoleError: {
      generic: 'Rolle konnte nicht gespeichert werden.',
      badRequest: 'Bitte prüfen Sie Ihre Eingaben.',
      nameInUse: 'Der Name wird bereits von einer anderen Rolle verwendet.'
    }
  },

  // copied from CAS MON
  identityProvider: {
    delete_button: {
      success_msg: 'ID-Provider gelöscht',
      errorMsg: 'ID-Provider konnte nicht gelöscht werden',
      not_found_error: 'ID-Provider existiert nicht mehr, bitte aktualisieren Sie die Seite.',
      has_user_error: 'ID-Provider kann nicht gelöscht werden, da diesem bereits Benutzer zugeordnet sind',
      delete_button: 'Löschen',
      confirm_delete: 'Ja, entfernen',
      deleted_id_provider1: 'Möchten Sie den ID-Provider',
      deleted_id_provider2: 'entfernen?',
      deleted_id_provider_info: 'Wenn Sie die Anmeldung nur temporär einschränken möchten, können Sie den ID-Provider stattdessen deaktivieren.'
    },
    editPage: {
      save_config_error: 'Fehler beim Speichern der Konfiguration',
      invalid_config_error: 'Ungültige Konfiguration - bitte prüfen Sie ihre Eingabe.',
      msg_header: 'Identity Provider konnte nicht geladen werden'
    },
    form: {
      connection_header: 'Verbindung',
      provider_id_help_text: 'Kann nach dem Erstellen nicht mehr geändert werden. Wird für Callback-URLs verwendet. Nur alphanumerische Zeichen und Bindestriche erlaubt.',
      active_label: 'Aktiv',
      active_help_text: 'Nur aktive Identity Provider können für den Login verwendet werden',
      name_help_text: 'Anzeigename des Providers',
      client_secret_input: '(unverändert)',
      representation_header: 'Darstellung',
      display_order_label: 'Anzeigereihenfolge',
      display_order_help_text: 'Auf dem Login-Bildschirm werden Identity Provider aufsteigend nach diesem Wert sortiert',
      color_label: 'Farbe',
      color_help_text: 'RGB HEX-Format. Zum Beispiel #2185d0',
      text_color_label: 'Text-Farbe',
      button_preview_header: 'Button-Vorschau',
      cancel_button: 'Abbrechen',
      save_button: 'Speichern'
    },
    list_page: {
      identity_provider_header: 'Identity Provider',
      paragraph: 'Konfigurieren Sie OpenID Connect Identity Provider, mit denen sich Benutzer am CAS RDH anmelden können.',
      add_id_provider: 'ID-Provider hinzufügen',
      table_header_active: 'Aktiv',
      table_header_type: 'Typ',
      table_header_display_order: 'Anzeigereihenfolge',
      call_data_error: 'Fehler beim Abruf der Daten',
      update_button: 'Aktualisieren',
      config_error: 'Keine Identity Provider konfiguriert',
      no_item: 'Nein',
      yes_item: 'Ja'
    },
    new_page: {
      save_config_error: 'Fehler beim Speichern der Konfiguration',
      already_exist_error: 'Es existiert bereits ein Identity Provider mit der Provider ID',
      invalid_config_error: 'Ungültige Konfiguration - bitte prüfen Sie ihre Eingabe.',
      add_id_provider: 'ID-Provider hinzufügen'
    },
    test_connection: {
      error: {
        invalid_url: 'Ungültige URL',
        invalid_protocol: 'URL muss mit http:// oder https:// beginnen',
        no_client_id: 'Client-ID muss angegeben werden',
        unknown: 'Unbekannter Fehler',
        internal: 'Unbekannter interner Fehler'
      },
      modal_header: 'OpenID Connect Verbindungstest',
      test_connection: 'Teste Verbindung...',
      error_msg_header: 'Test fehlgeschlagen',
      success_msg_header: 'Test erfolgreich',
      validation_error_msg: 'Es wird nur die Erreichbarkeit des OpenID Connect Metadaten Endpunktes geprüft. Die Gültigkeit von Client-ID und Client-Secret wird in diesem Schritt nicht validiert.',
      close_button: 'Schließen',
      test_availability_button: 'Erreichbarkeit testen'
    },
    view: {
      error_header: 'Identity Provider konnte nicht geladen werden',
      edit_button: 'Bearbeiten',
      connection_header: 'Verbindung',
      active_label: 'Aktiv',
      button_preview_header: 'Button-Vorschau'
    },
    input_image: {
      size_error: 'Datei überschreitet maximale Größe von ',
      format_error: 'Nicht zulässiges Dateiformat'
    },
    without: 'ohne'
  }
};

export default {
  en,de
};
