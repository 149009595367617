/*
 * CAS-MDH
 * Proprietary Software Solution
 * CAS Concepts and Solutions AG
 * Copyright (C) 2018 CAS Concepts and Solutions AG
 * mailto: info [at] c-a-s [dot] de
 *
 * CAS-MDH is a proprietary software solution distributed and licensed
 * by CAS Concepts and Solutions AG
 * You may not modify or change this code without permission.
 */

import RestClient from "./RestClient";

class EntityApiClient {
  static getEntityListSortedByDisplayName() {
    return RestClient.get('v2/data')
        .then(response => {
          if(Array.isArray(response.data)) {
            response.data.sort((a,b) => {
              const nameA = a.displayName ? a.displayName.toLowerCase() : '';
              const nameB = b.displayName ? b.displayName.toLowerCase() : '';
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
          return response;
        });
  }
}

export default EntityApiClient;
