const en = {
  header: 'Spreadsheet document import of the entity "{{displayName}}"',

  importNotStarted: {
    uploadFile: "Please upload the Spreadsheet file you want to import.",
    note: "Note:",
    notes: {
      format: "Only xlsx format is supported.",
      oneFile: "The xlsx file should contain only one worksheet. ",
      dataModelConform: "Documents that do not conform to the data model will not be imported. The table headings must correspond to the RDH field names. ",
      notStoppable: "The import cannot be stopped once it has been started.",
    },
    fileUploadHint: "Drag 'n' drop an xlsx file here or click here to select a file.",
    fileHeader: "File",
    fileInfo: "{{fileName}} - {{fileSize}} Bytes",
    confirmation: "Do you want to import the documents from this Spreadsheet file?",
    error: {
      fileNotXlsx: "Uploaded file is not a .xlsx file!"
    }
  },
  importStarted: {
    excelBeingUploaded: "{{fileName}} is being uploaded",
    xDocumentsImported: "{{amountImportedDocuments}} documents have been imported",
    convertDocuments: "Import documents...",
    connectionHint: "Please ensure a stable internet connection during the import and do not close the application!",
  },
  importFailed: {
    generalErrorMessage: "An error has occurred. Please make sure that the xlsx file meets the requirements and try again.",
    alreadyInProgressErrorMessage: "An import or export is currently in progress for this entity. Please try again later.",
    missingPermissions: "You do not have the permissions to import documents for this entity.",
    fileTooLarge: "The uploaded file is too large."
  },
  importFinished: {
    processedMessage: "A total of {{amountProcessed}} documents were processed.",
    createdMessage: "{{amountCreated}} documents have been created.",
    updatedMessage: "{{amountUpdated}} documents have been updated.",
    invalidDocumentMessage: "{{amountInvalid}} documents could not be imported because they do not correspond to the data model.",
    alreadyExistsMessage: "{{amountDuplicate}} documents already existed and were not imported again.",
    missingPermissionMessage: "{{count}} documents could not be imported because of missing permissions.",
  },
  errorMessage: {
    buttonShow: "Show error messages",
    buttonHide: "Hide error messages",
    row: "Row"
  },

  buttonContinue: "Continue",
  buttonBack: "Back",
  buttonCancel: "Cancel",
  buttonClose: "Close",
  buttonStartImport: "Start import",
};

const de = {
  header: 'Spreadsheet-Import der Entität "{{displayName}}"',

  importNotStarted: {
    uploadFile: "Bitte laden Sie die zu importierende Spreadsheet-Datei hoch.",
    note: "Beachten Sie:",
    notes: {
      format: "Es wird nur das xlsx Dateiformat unterstützt.",
      oneFile: "Die xlsx Datei sollte nur ein Arbeitsblatt beinhalten.",
      dataModelConform: "Dokumente, die nicht dem Datenmodell entsprechen, werden nicht importiert. Die Tabellenüberschriften müssen den RDH-Feldnamen entsprechen.",
      notStoppable: "Der Import kann nicht mehr gestoppt werden, sobald dieser gestartet wurde.",
    },
    fileUploadHint: "Ziehen Sie eine xlsx-Datei per Drag 'n' Drop hierher oder klicken Sie hier, um eine Datei auszuwählen.",
    fileHeader: "Datei",
    fileInfo: "{{fileName}} - {{fileSize}} Bytes",
    confirmation: "Möchten Sie die Dokumente aus dieser Spreadsheet-Datei importieren?",
    error: {
      fileNotXlsx: "Hochgeladene Datei ist keine .xlsx Datei!"
    }
  },
  importStarted: {
    excelBeingUploaded: "{{fileName}} is being uploaded",
    xDocumentsImported: "{{amountImportedDocuments}} Dokumente wurden importiert",
    convertDocuments: "Importiere Dokumente...",
    connectionHint: "Bitte gewährleisten Sie während des Imports eine stabile Internetverbindung und schließen Sie die Applikation nicht!",
  },
  importFailed: {
    generalErrorMessage: "Es ist ein Fehler aufgetreten. Bitte vergewissern Sie sich, dass die Daten der XLSX-Datei den " +
      "Voraussetzungen entsprechen und versuchen Sie es erneut.",
    alreadyInProgressErrorMessage: "Für diese Entität läuft zurzeit ein Import oder Export. Bitte versuchen Sie es später erneut.",
    missingPermissions: "Sie verfügen nicht die Berechtigungen zum Importieren von Dokumenten für diese Entität.",
    fileTooLarge: "Die hochgeladene Datei ist zu groß."
  },
  importFinished: {
    processedMessage: "Insgesamt wurden {{amountProcessed}} Dokumente verarbeitet.",
    createdMessage: "{{amountCreated}} Dokumente wurden erstellt.",
    updatedMessage: "{{amountUpdated}} Dokumente wurden aktualisiert.",
    invalidDocumentMessage: "{{amountInvalid}} Dokumente konnten nicht importiert werden, weil sie nicht dem Datenmodell entsprechen.",
    alreadyExistsMessage: "{{amountDuplicate}} Dokumente waren bereits vorhanden und wurden nicht erneut importiert.",
    missingPermissionMessage: "{{count}} Dokumente konnten aufgrund fehlender Berechtigungen nicht importiert werden.",
  },
  errorMessage: {
    buttonShow: "Fehlermeldungen anzeigen",
    buttonHide: "Fehlermeldungen ausblenden",
    row: "Zeile"
  },

  buttonContinue: "Fortfahren",
  buttonBack: "Zurück",
  buttonCancel: "Abbrechen",
  buttonClose: "Schließen",
  buttonStartImport: "Import starten",
};

export default {
  en, de
};
