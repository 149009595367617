import {Button, ButtonProps} from "semantic-ui-react";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface IdentityProviderButtonParameters {
  providerId: string;
  displayName: string;
  buttonText?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  icon?: string;
}

export const DEFAULT_ID_PROVIDER_BUTTON_BACKGROUND_COLOR = '#ffffff';
export const DEFAULT_ID_PROVIDER_BUTTON_FOREGROUND_COLOR = '#444444';

export const IdProviderLoginButton = (props: {provider: IdentityProviderButtonParameters, preview?: boolean} & Partial<ButtonProps>) => {
  const {t} = useTranslation();
  const {provider, preview, ...remainingProps} = props;
  const providerDisplayName = provider.displayName && provider.displayName.length > 0 ? provider.displayName : 'Identity Provider';
  const text = provider.buttonText || `${t('auth:login.link_sso_login')} ${providerDisplayName}`;
  const color = provider.backgroundColor && provider.backgroundColor.length > 0 ? provider.backgroundColor : DEFAULT_ID_PROVIDER_BUTTON_BACKGROUND_COLOR;
  const textColor = provider.foregroundColor && provider.foregroundColor.length > 0 ? provider.foregroundColor : DEFAULT_ID_PROVIDER_BUTTON_FOREGROUND_COLOR;
  const icon = provider.icon && provider.icon.length > 0 ? <img style={{maxWidth:'2em', maxHeight: '1em', marginRight: '1em', verticalAlign: 'top'}} src={provider.icon} alt="" /> : undefined;
  return (
    <Button
      type="button"
      primary
      style={{backgroundColor: color, color: textColor, border: '1px solid rgba(34,36,38,.15)'}}
      content={<>{icon}{text}</>}
      onClick={() => {
        if(!props.preview) {
          window.location.href = `./oauth2/authorization/${provider.providerId}`;
        }
      }}
      {...remainingProps}
    />
  )
};