/*
 * CAS-MDH
 * Proprietary Software Solution
 * CAS Concepts and Solutions AG
 * Copyright (C) 2018 CAS Concepts and Solutions AG
 * mailto: info [at] c-a-s [dot] de
 *
 * CAS-MDH is a proprietary software solution distributed and licensed
 * by CAS Concepts and Solutions AG
 * You may not modify or change this code without permission.
 */

import {withRouter, RouteComponentProps} from 'react-router';
import * as React from 'react';

interface ErrorBoundaryProps extends RouteComponentProps<{}> {
  errorMessage?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  historyListener?;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    this.historyListener = this.props.history.listen(() => { // when route changes, try loading again.
      if (this.state.hasError) {
        this.setState({hasError: false});
      }
    });
  }

  componentWillUnmount(): void {
    if(typeof this.historyListener !== 'undefined') {
        this.historyListener();
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // log error to rdh?
  }

  render() {
    if (this.state.hasError) {
      return <h1>{this.props.errorMessage || 'Error rendering component.'}</h1>;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);