import * as React from "react";
import {Dropdown, Icon} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {Language} from "../../lib/language";

// TODO: Currently hard coded -> Extract to collection in mongodb as a setting
export const availableLanguages = [Language.ENGLISH, Language.GERMAN]

const LanguageSelector = () => {
  const {t, i18n} = useTranslation();
  
  return ( <Dropdown trigger={<span><Icon name="globe"/> {t('common:i18n.language')}</span>} >
    <Dropdown.Menu>
      {availableLanguages.map(
          languageAbbreviation => <Dropdown.Item text={t('common:i18n.languageTypes.'+languageAbbreviation)}
                                                 onClick={() => i18n.changeLanguage(languageAbbreviation)}/>)
      }
    </Dropdown.Menu>
  </Dropdown>);
};

export default LanguageSelector;
