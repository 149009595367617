import RestClient from '../../lib/RestClient';
import entityTypeListObservableStore from "./entityTypeListObservableStore";

interface AuthCredentials {
    email: string;
    password: string;
}

export interface AuthPrincipal {
    email: string;
    name: string;
    roles: string[];
    featurePermission: any[];
}

const LOGIN_URL = 'users/authenticate';
const LOGOUT_URL = 'users/logout';
const ME_URL = 'users/me';

class AuthService {
    private _loggedIn: boolean = false;
    private _principal?: AuthPrincipal;

    get principal(): AuthPrincipal {
        return this._principal || {} as AuthPrincipal;
    }

    set principal(value: AuthPrincipal) {
        this._principal = value;
    }

    get loggedIn(): boolean {
        return this._loggedIn;
    }

    set loggedIn(value: boolean) {
        this._loggedIn = value;
    }

    checkIfLoggedIn() {
        return new Promise<void>((resolve, reject) => {
            RestClient.get(ME_URL).then((response) => {
                this.loggedIn = true;
                this._principal = response.data;
                resolve();
            }).catch((error) => {
                this.loggedIn = false;
                reject();
            });
        });
    }

    login(credentials: AuthCredentials) {
        let formData = {email: credentials.email, password: credentials.password};
        return new Promise((resolve, reject) => {
            RestClient.post(LOGIN_URL, formData)
                .then((response) => {
                    this.checkIfLoggedIn().then(resolve, reject);
                })
                .catch((error) => {
                    this.loggedIn = false;
                    let message = 'unspecified';
                    if (error.response) {
                        if (error.response.status === 401) {
                            message = 'bad_credentials';
                        } else if (error.response.status === 403) {
                            message = 'xsrf_error';
                        } else if (error.response.status === 500) {
                            message = 'internal_error';
                        }
                    } else if (error.request) {
                        message = 'response_error';
                    } else {
                        message = 'request_error';
                    }
                    reject(message);
                });
        });
    }

    logout() {
        return new Promise<void>((resolve, reject) => {
            RestClient.post(LOGOUT_URL).then(() => {
                this.loggedIn = false;
                entityTypeListObservableStore.reset();
                resolve();
            }).catch((err) => reject(err));
        });
    }
    
    setPrincipal(principal: AuthPrincipal) {
        return new Promise(
            function (resolve, reject) {
                RestClient.post(ME_URL, principal).then(
                    resolve,
                    reject
                );
            }
        );
    }

    changePassword(changePasswordRequest: {
        currentPassword: string;
        newPassword: string;
        newPasswordConfirm: string;
    }) {
        return RestClient.post(ME_URL + '/password', changePasswordRequest);
    }
}

const authService = new AuthService();

export default authService;
