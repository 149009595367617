import React, {useCallback, useEffect, useState} from "react";
import InfoService from "../Core/InfoService";

interface AppInfo {
  name: string | null;
  logo: string | null;
  baseUrl: string | null;
}

interface AppInfoContextData extends AppInfo {
  reload: () => void;
}

export const AppInfoContext = React.createContext({
  name: null,
  logo: null,
  baseUrl: null,
  reload: () => {}
} as AppInfoContextData);

export function AppInfoContextProvider(props) {
  const [appInfo, setAppInfo] = useState<AppInfo>({name: null, logo: null, baseUrl: null});

  const reload = useCallback(() => {
    console.log('Loading Environment');
    InfoService.getEnvironmentInfo(true).then((appInfo) => {
      console.log('Loaded, seting environment');
      setAppInfo({...appInfo} as AppInfo);
    }).catch(e => {
      // no error handling for this - no user action
      console.error('Error fetching environment data ', e);
    })
  }, []);

  const [context, setContext] = useState<AppInfoContextData>({
    ...appInfo, reload
  });

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    console.log('Updating Context to ', {...appInfo, reload});
    setContext({...appInfo, reload})
  }, [appInfo, reload]);


  return <AppInfoContext.Provider value={context}>
      {props.children}
  </AppInfoContext.Provider>
}