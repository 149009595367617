import RestClient from '../../lib/RestClient';
import {useEffect, useState} from "react";

const API_URL = 'frontend/web-service/info';
const ENVIRONMENT_INFO_URL = 'internal/info';

class InfoService {
    private baseUrl: string = '';
    private environmentSettings: object|null = null;

    getBaseService(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.baseUrl) {
                resolve(this.baseUrl);
            } else {
                RestClient.get(API_URL).then((response) => {
                    this.baseUrl = response.data.baseUrl;
                    resolve(this.baseUrl);
                }).catch((err) => {
                    console.error('Failed to get BaseUrl', err);
                    reject();
                });
            }
        });
    }

    getEnvironmentInfo(forceReload = false): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this.environmentSettings && !forceReload) {
                resolve(this.environmentSettings);
            } else {
                this.fetchEnvironmentInfo().then(environmentData => {
                    this.environmentSettings = environmentData;
                    resolve(environmentData);
                }).catch(error => {
                    console.error('Failed to get environment settings', error);
                    reject(error);
                });
            }
        });
    }

    fetchEnvironmentInfo(): Promise<object> {
        return new Promise((resolve, reject) => {
            RestClient.get(ENVIRONMENT_INFO_URL).then((response) => {
                if (!response.data) {
                    reject(new Error('No response data'));
                    return;
                }
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    setEnvironmentInfo(newInfo) : Promise<void> {
        return new Promise(function(resolve, reject) {
           RestClient.post(ENVIRONMENT_INFO_URL, newInfo).then( (response) => resolve(), (error) => reject(error));
        });
    }
}

interface EnvironmentInfo {
    baseUrl: string;
    name: string;
    logo: string;
}

export const useEnvironmentInfo = () => {
    const [environmentInfo, setEnvironmentInfo] = useState({} as Partial<EnvironmentInfo>);

    useEffect(() => {
        RestClient.get(ENVIRONMENT_INFO_URL)
          .then(response => {
             setEnvironmentInfo(response.data);
          });
    }, []);

    return [environmentInfo];
};

export default new InfoService();
