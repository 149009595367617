const en = {
  header: 'Spreadsheet document export of the entity "{{displayName}}"',

  exportNotStarted: {
    amountOfDocuments: "At this time {{numberOfDocuments}} documents have been found.",
    confirmation: "Do you want to export the documents as an Spreadsheet file?",
    note: "Note:",
    notes: {
      systemLimit: "A maximum of 1,000,000 documents will be exported.",
      filtersUsed: "You can limit the result set by using the search / filters above the table.",
      excelLimits: "A sheet is ignored if it exceeds the XLSX Spreadsheet limits (e.g. maximum number of rows (1,048,576)).",
    },
  },
  exportStarted: {
    convertDocuments: "Export documents...",
    xDocumentsExported: "{{amountExportedDocuments}} documents were processed",
    writeExcel: "{{amountExportedDocuments}} documents are written to an Spreadsheet file",
    connectionHint: "Please ensure a stable internet connection during the export and do not close the application!",
  },
  exportFailed: {
    generalErrorMessage: "An unexpected error occurred during export. Please ensure a stable internet connection during export.",
    alreadyInProgressErrorMessage: "An import or export is currently in progress for this entity. Please try again later.",
    missingPermissions: "You do not have the permissions to export documents for this entity."
  },
  exportFinished: {
    header: "Export finished successfully",
    successMessage: "{{amountExportedDocuments}} documents were exported successfully.",
    ignoredSheetsHint: "The following sheets were ignored because they exceed the XLSX limits:",
    buttonDownloadExcel: "Download XLSX"
  },
  cancel: {
    header: "Cancel export",
    confirmation: "Are you sure you want to cancel the export?",
  },

  buttonClose: "Close",
  buttonCancel: "Cancel",
  buttonNo: "No",
  buttonYes: "Yes",
  buttonStartExport: "Start export",
};

const de = {
  header: 'Spreadsheet-Export der Entität "{{displayName}}"',

  exportNotStarted: {
    amountOfDocuments: "Zum jetzigen Zeitpunkt wurden {{numberOfDocuments}} Dokumente gefunden.",
    confirmation: "Möchten Sie die Dokumente als Spreadsheet-Datei exportieren?",
    note: "Beachten Sie:",
    notes: {
      systemLimit: "Es werden maximal 1.000.000 Dokumente exportiert",
      filtersUsed: "Sie können die zu exportierenden Dokumente über die Suche / Filter einschränken",
      xlsxLimit: "Ein Sheet wird ignoriert, wenn es die XLSX Spreadsheet-Limitationen (bspw. maximale Anzahl an Rows (1,048,576)) überschreitet.",

    },
  },
  exportStarted: {
    convertDocuments: "Exportiere Dokumente...",
    xDocumentsExported: "{{amountExportedDocuments}} Dokumente wurden verarbeitet",
    writeExcel: "{{amountExportedDocuments}} Dokumente werden in eine Spreadsheet-Datei geschrieben",
    connectionHint: "Bitte gewährleisten Sie während des Exports eine stabile Internetverbindung und schließen Sie die Applikation nicht!",
  },
  exportFailed: {
    generalErrorMessage: "Beim Export ist ein unbekannter Fehler aufgetreten. Bitte stellen Sie während des Exports eine durchgehende Netzwerkverbindung sicher.",
    missingPermissions: "Sie verfügen nicht die Berechtigungen zum Exportieren von Dokumenten für diese Entität."
  },
  exportFinished: {
    successMessage: "{{amountExportedDocuments}} Dokumente wurden erfolgreich exportiert.",
    ignoredSheetsHint: "Folgende Sheets wurden ignoriert, da sie die XLSX-Limitation überschreiten:",
    buttonDownloadExcel: "Spreadsheet herunterladen"
  },
  cancel: {
    header: "Export abbrechen",
    confirmation: "Sind Sie sicher, dass Sie den Export abbrechen wollen?",
  },

  buttonClose: "Schließen",
  buttonCancel: "Abbrechen",
  buttonNo: "Nein",
  buttonYes: "Ja",
  buttonStartExport: "Export starten",

};

export default {
  en, de
};
