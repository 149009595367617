import {JSONSchema6} from "json-schema";
import _get from "lodash/get";

export function isJsonSchema6(obj: JSONSchema6 | boolean): obj is JSONSchema6 {
	return typeof obj !== "boolean"
}


// ---

/**
 *
 *  Search for a propertyPath (dot separated) in schema. Resolves refs.
 *  Also can check if the type matches expectation with optional expectedType param.
 *
 *
 * @param jsonSchema JSON schema to search in
 * @param propertyPath propertyPath to search for
 * @param expectedType expected type -> to compare if the found field matches type expectations
 */
export function searchPropertyInJsonSchema(jsonSchema: Record<string, any>, propertyPath: string, expectedType?: string): {found: boolean, typeMatch: boolean, type: string | undefined} {
	if(!jsonSchema) {
		return {found: true, typeMatch: true, type: undefined}; // no schema, any field is valid
	}
	try {
		return searchPropertyInSchemaRec(propertyPath, expectedType, jsonSchema, jsonSchema);
	} catch(e) {
		// TODO this could be handled better - this function was extracted from entity settings where it was useful this way
		//       should add some kind of error return type or exception
		return {found: true, typeMatch: true, type: undefined}; // when schema is invalid pretend no schema is present
	}
}

/**
 * Search for a propertyPath (dot separated) in schema. Resolves refs.
 *
 * @param propertyPath propertyPath to search
 * @param expectedType expected type -> to compare if the found field matches type expectations
 * @param currentSchemaObject current Object to search
 * @param rootSchemaObject root schema object - required to resolve refs
 * @private
 */
function searchPropertyInSchemaRec(propertyPath: string, expectedType: string | undefined, currentSchemaObject: Record<string, any>, rootSchemaObject: Record<string, any>): {
	found: boolean;
	typeMatch: boolean;
	type: string | undefined;
} {
	try {
		const pathParts = propertyPath.length > 0 ? propertyPath.split(".") : [];

		for(let i = 0; i < pathParts.length; i++) {
			const pathPart = pathParts[i];

			let schemaObj: any = _get(currentSchemaObject, 'properties.' + pathPart);
			if(typeof schemaObj === 'object' && schemaObj !== null) {
				if(schemaObj['$ref'] && typeof schemaObj['$ref'] === 'string') {
					// handle ref
					const refDotPath = schemaObj['$ref'].split("/").filter(r => r !== "#").join(".");
					const definition = _get(rootSchemaObject, refDotPath);
					if(definition) {
						const remainingPath = pathParts.slice(i + 1).join('.');
						return searchPropertyInSchemaRec(remainingPath, expectedType, definition, rootSchemaObject);
					} else {
						return {found: false, typeMatch: false, type: undefined};
					}
				} else {
					currentSchemaObject = schemaObj;
				}
			} else {
				return {found: false, typeMatch: false, type: undefined};
			}
		}

		let typeMatch = !expectedType ? true : currentSchemaObject.type === expectedType;
		return {found: true, typeMatch: typeMatch, type: currentSchemaObject.type};
	} catch(e) {
		return {found: true, typeMatch: true, type: undefined}; // when schema is invalid pretend no schema is present
	}
}


export function extractTopLevelPropertyPathsWithType(searchedTypes: string[], schema: Record<string, any>): {
	path: string;
	type: string;
}[] {

	return extractTopLevelPropertyPathsWithTypeRec(searchedTypes, '', schema, schema);
}

function extractTopLevelPropertyPathsWithTypeRec(searchedTypes: string[], currentPrefix: string, currentSchemaObject: Record<string, any>, rootSchemaObject: Record<string, any>): {
	path: string;
	type: string;
}[] {
	try {
		if (currentSchemaObject['properties'] && typeof currentSchemaObject['properties'] === 'object') {
			const result: {
				path: string;
				type: string;
			}[]  = [];

			const propertyKeys = Object.keys(currentSchemaObject['properties']);
			const properties = currentSchemaObject['properties'];

			for (let propertyKey of propertyKeys) {
				const property = properties[propertyKey];
				const propertyPath = [currentPrefix, propertyKey].filter(s => !!s).join('.');
				if (property['$ref'] && typeof property['$ref'] === 'string') {
					const refDotPath = currentSchemaObject['$ref'].split("/").filter(r => r !== "#").join(".");
					const definition = _get(rootSchemaObject, refDotPath);
					if(definition) {
						const subProperties = extractTopLevelPropertyPathsWithTypeRec(searchedTypes, propertyPath, definition, rootSchemaObject);
						result.push(...subProperties);
					}
				} else if (property['type'] === 'object') {
					const subProperties = extractTopLevelPropertyPathsWithTypeRec(searchedTypes, propertyPath, property, rootSchemaObject);
					result.push(...subProperties);
				} else if (searchedTypes.includes(property['type'])) {
					result.push({
						path: propertyPath,
						type: property['type']
					});
				}
			}

			return result;
		} else {
			return [];
		}
	} catch(e) {
		return [];
	}
}