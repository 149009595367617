/*
 * CAS-MDH
 * Proprietary Software Solution
 * CAS Concepts and Solutions AG
 * Copyright (C) 2019 CAS Concepts and Solutions AG
 * mailto: info [at] c-a-s [dot] de
 *
 * CAS-MDH is a proprietary software solution distributed and licensed
 * by CAS Concepts and Solutions AG
 * You may not modify or change this code without permission.
 */

import * as _get from 'lodash.get';
import {
	Entity,
	EntityTranslationObject,
	EntityTranslationObjectWithTranslationValues,
	EntityTranslations,
	TextObject,
	Translations,
	ViewComponent
} from "../Core/models";
import {isValidLanguage} from "../../lib/language";
import {Path, PathId} from "../Common/ExpandableInputTable/models";
import {JSONSchema6} from "json-schema";
import {isJsonSchema6} from "../../lib/json-schema-utils";
import {isDefined} from "../../lib/type-guards";
import {UiSchema} from "@rjsf/core";

export const getCompoundApiKey = (entity: Entity, document: object) => {
	return entity.keyList.map((key) => encodeURIComponent(_get(document, key.propertyPath))).join('/');
};

/**
 *
 * @param currentTranslationObject - holds the current translation object
 * @param objKey - current key, under which the translation object is found
 * @param path - path given by the component with all remaining elements - can also contain attribute names
 */
export const findTranslationByPath = (
	currentTranslationObject: EntityTranslationObject | EntityTranslationObjectWithTranslationValues,
	path: Path, objKey?: PathId
): Translations | undefined => {
	
	// If the provided objKey matches the first path element -> the next key was found aka. the current object matches the desired path element
	if (path[0] === objKey) {
		path.shift();
	}
	
	// If path is empty -> a correlated object was found
	if (path.length === 0) {
		return isPropertiesWithTranslations(currentTranslationObject) ? currentTranslationObject.values : undefined;
	} else {
		
		if (typeof path[0] === "string" && isPropertyType(path[0])) {
			path.shift();
		}
		
		if (currentTranslationObject.items) {
			
			let resolvedItems: Translations | undefined = undefined;
			
			if (Array.isArray(currentTranslationObject.items)) {
				for (let i = 0; i < currentTranslationObject.items.length && resolvedItems === undefined; i++) {
					resolvedItems = findTranslationByPath(currentTranslationObject.items[i], path, i);
				}
			} else {
				resolvedItems = findTranslationByPath(currentTranslationObject.items, path, "items");
			}
			if (resolvedItems) return resolvedItems;
		}
		
		// If key wasn't found so far -> search in properties if given
		if (currentTranslationObject.properties) {
			let resolvedProperties: Translations | undefined = undefined;
			
			const propertiesArray = Object.entries(currentTranslationObject.properties);
			
			for (let i = 0; i < propertiesArray.length && resolvedProperties === undefined; i++) {
				const key = propertiesArray[i][0];
				const translation = propertiesArray[i][1];
				
				if (!isPropertyType(key) && key !== path[0]) {
					continue;
				}
				
				resolvedProperties = findTranslationByPath(translation, path, key);
			}
			
			if (resolvedProperties) return resolvedProperties;
		}
		
		// If key wasn't found so far -> search in definitions if given
		if (currentTranslationObject.definitions) {
			let resolvedDefinitions: Translations | undefined = undefined;
			
			const definitionsArray = Object.entries(currentTranslationObject.definitions);
			for (let i = 0; i < definitionsArray.length && resolvedDefinitions === undefined; i++) {
				
				const key = definitionsArray[i][0];
				const translation = definitionsArray[i][1];
				if (!isPropertyType(key) && key !== path[0]) {
					continue;
				}
				resolvedDefinitions = findTranslationByPath(translation, path, key);
			}
			if (resolvedDefinitions) return resolvedDefinitions;
		}
		
		return undefined;
	}
};

/**
 *
 * @param jsonPath - single string with "." to indicate object traversal by keys
 */
const getPathArrayFromJsonPath = (jsonPath: string): Path => jsonPath.split(".");

/**
 * Returns given path as array of strings and filters out the root element
 * @param refPath - string that starts with # (for root) and continues with further path elements - example: #/definitions/LocationType
 */
export const getPathArrayFromRefPath = (refPath: string): Path => refPath.split("/").filter(elem => elem !== "#");

const isPropertiesWithTranslations = (obj: EntityTranslationObject | EntityTranslationObjectWithTranslationValues): obj is EntityTranslationObjectWithTranslationValues => {
	return obj["values"] !== undefined;
}

/**
 *
 * @param translation
 * @param selectedLanguage - must be contained in enum Language to return a value other than undefined
 */
export const getDisplayName = (translation: EntityTranslations, selectedLanguage: string): string | undefined => {
	return isValidLanguage(selectedLanguage) ? translation.title.values[selectedLanguage] : undefined;
}

export const isTextObject = (obj: TextObject | string): obj is TextObject => typeof obj === "object";

/**
 * Transforms all existing text objects into simple strings by reference
 * Text Objects can exist in place of strings within the attributes label, rightLabel and leftLabel
 * If a text object is given:
 * 1.a. If defined ->   Look for translations for the selected language within the translations object
 * 1.b. None found & if defined -> Search through translations object within the provided entity for a valid translation by the attribute translationPath within the text object
 * 1.c. None found ->   Return the specified default value
 * @param component
 * @param entity
 * @param selectedLanguage
 * @return a deep copy of the component in case of it containing TextObject objects for label, leftLabel or rightLabel
 */
export const resolveDisplayedTextForComponent = ({
	                                                 component,
	                                                 entity,
	                                                 selectedLanguage
                                                 }: { component: ViewComponent, entity?: Entity, selectedLanguage: string }): ViewComponent => {
	
	// If one textObject condition applies -> create a clone so that the original component doesn't get altered
	let componentCopy: ViewComponent | undefined = undefined;
	
	// The object is deep cloned, not to manipulate the original structure
	const conditionallyCreateComponentCopy = (callback: (cmp: ViewComponent) => void) => {
		if (!componentCopy) componentCopy = {...component};//cloneDeep(component);
		callback(componentCopy);
	}
	// 1. Resolve every label individually if defined and in case of it being an object
	// 1.1 Resolve base label
	if (isDefined(component.label) && isTextObject(component.label)) {
		conditionallyCreateComponentCopy((compCopy) => {
			isDefined(component.label)
			&& isTextObject(component.label)
			&& (compCopy.label = resolveTextObject({
				textObject: component.label,
				skipNumericArrayElementsInPath: true,
				entity,
				selectedLanguage
			}))
		});
		// 1.1.1 Some components use a field if the label is not defined. Due to the component not having access to the appropriate entity
		//      the field attribute is resolved in advance and set to the component attribute "optionalLabel"
		//      This new attribute only serves for calculations and should not be considered an addition to the component API
	} else if (!component.label && component.field && entity) {
		conditionallyCreateComponentCopy((compCopy) => {
			!component.label && component.field && entity
			&& (compCopy.optionalLabel = getDisplayedText(
				{
					entity,
					jsonPathString: component.field,
					skipNumericArrayElementsInPath: true,
					selectedLanguage
				}))
		});
	}
	// 1.2  Resolve right label
	if (isDefined(component.rightLabel) && isTextObject(component.rightLabel)) {
		conditionallyCreateComponentCopy((compCopy) => {
			isDefined(component.rightLabel)
			&& isTextObject(component.rightLabel)
			&& (compCopy.rightLabel = resolveTextObject({
				textObject: component.rightLabel,
				entity,
				skipNumericArrayElementsInPath: true,
				selectedLanguage
			}))
		});
	}
	// 1.3  Resolve left label
	if (isDefined(component.leftLabel) && isTextObject(component.leftLabel)) {
		conditionallyCreateComponentCopy((compCopy) => {
			isDefined(component.leftLabel)
			&& isTextObject(component.leftLabel)
			&& (compCopy.leftLabel = resolveTextObject({
				textObject: component.leftLabel,
				entity,
				skipNumericArrayElementsInPath: true,
				selectedLanguage
			}))
		});
	}
	
	const mapViewCompFunc = (childComp: ViewComponent) => resolveDisplayedTextForComponent({
		component: childComp,
		entity,
		selectedLanguage
	});
	
	// 2. Find translations for all children of component if given
	if (component.children) {
		if (componentCopy && componentCopy['children']) {
			componentCopy = componentCopy as ViewComponent; //  Too lazy to implement correctly -> works either way
			componentCopy.children = componentCopy.children?.map(mapViewCompFunc);
		} else {
			component = {...component, children: component.children.map(mapViewCompFunc)}
		}
	}
	
	// 3. Find translations for items if given
	if (component.items) {
		if (componentCopy && componentCopy['items']) {
			componentCopy = componentCopy as ViewComponent; //  Too lazy to implement correctly -> works either way
			componentCopy.items && (componentCopy.items = mapViewCompFunc(componentCopy.items));
		} else {
			component = {...component, items: mapViewCompFunc(component.items)}
		}
	}
	
	// 3. Return the copied version if there were text objects, else return the original
	return componentCopy || component;
}

/**
 *
 * @param textObject
 * @param entity
 * @param selectedLanguage - describe a language abbreviation like "de", "en" or "nl"
 * @param skipNumericArrayElementsInPath - propagated to function getDisplayedText to resolve the path with conditions
 */
export const resolveTextObject = ({
	                                  textObject,
	                                  entity,
	                                  selectedLanguage,
	                                  skipNumericArrayElementsInPath
                                  }: { textObject: TextObject, skipNumericArrayElementsInPath?: boolean; entity?: Entity, selectedLanguage: string }): string | undefined => {
	let resultingText: string | undefined;
	if (textObject.translations) {
		resultingText = textObject.translations[selectedLanguage]
	}
	if (resultingText === undefined) {
		if (textObject.translationPath && entity) {
			resultingText = getDisplayedText({
				entity,
				selectedLanguage,
				skipNumericArrayElementsInPath,
				jsonPathString: textObject.translationPath
			})
		}
	}
	return resultingText || textObject.default;
}

/**
 * @param entity - The entity where the json path string belongs to
 * @param alt - Alternative/default descriptor (string) if no translation was found
 * @param jsonPathString - a path made up of strings and integers connected via dots (".")
 * @param skipNumericArrayElementsInPath - flag to ignore strings containing numbers or integers inside of the jsonPathString
 * after splitting it into an array of elements. The origin for this lies within some components referencing instances of an
 * entity/bucket instead of the entity/bucket itself. Translations on the other hand are managed via the provided schema
 * which doesn't take instances of entities/buckets into consideration. An attempt to retrieve the translation either way
 * is to exclude the array suggestive elements of the path array and search through the json schema based translations if requested.
 * @param selectedLanguage - language abbreviation (e.g. "en" or "de")
 */
export const getDisplayedText = <T extends string | undefined>(
	{
		entity,
		alt,
		jsonPathString,
		skipNumericArrayElementsInPath,
		selectedLanguage
	}: {
		entity: Entity, jsonPathString: string, skipNumericArrayElementsInPath?: boolean, selectedLanguage: string, alt?: T
	}): string | T => {
	
	let resolvedRootSchema: JSONSchema6 | undefined = undefined;
	if (entity.rootSchema) {
		try {
			resolvedRootSchema = JSON.parse(entity.rootSchema);
		} catch (e) {
			console.error("Error while resolving root schema")
		}
	}
	
	let fullPath = getPathArrayFromJsonPath(jsonPathString);
	
	if (skipNumericArrayElementsInPath) {
		// Filters out array elements of numbers and strings only containing numbers
		// -> Only keep strings that aren't made up of numbers
		fullPath = fullPath.filter((item) => typeof item === "string" && !item.match(/^-?\d+$/));
	}
	
	// 1. Resolve the given path towards the last ref element with the remaining path - example: [a,b,ref,c,d] -> [c,d]
	//      Refs exist where a path element in between points to an entry in the entity with a ref attribute with no further matching elements and remaining path elements
	//      If no path element persist -> the last element was already found
	
	let resolvedPath: Path | null = null;
	if (resolvedRootSchema) {
		resolvedPath = resolveRootPath(fullPath, 0, resolvedRootSchema, resolvedRootSchema);
	}
	
	// 2. Iterate through the translation tree and find the corresponding translation to the resolved path
	const foundTranslation = isValidLanguage(selectedLanguage)
		&& entity.translations
		&& resolvedPath
		&& findTranslationByPath(entity.translations.properties, resolvedPath);
	
	return (foundTranslation && foundTranslation[selectedLanguage]) || alt
}

enum PROPERTY_TYPES {properties = "properties", definitions = "definitions", items = "items"}

const isPropertyType = (value: string): value is PROPERTY_TYPES => Object.values(PROPERTY_TYPES).some(prop => prop === value);

// path can contain keys "properties", "items" and "definitions"
const resolveRootPath = (path: (PathId | PROPERTY_TYPES)[], pathPos: number, entitySchema: JSONSchema6, ogEntitySchema: JSONSchema6): Path | null => {
	
	if (path.length === pathPos) return path;
	
	// Check if path element is contained in properties, definitions or items
	else {
		const cElem = path[pathPos];
		const isCurrentPathElemPropertyType = typeof cElem === "string" && isPropertyType(cElem);
		
		// If a property type was specified -> only traverse through exactly that element
		const restrictProperties = isCurrentPathElemPropertyType && PROPERTY_TYPES.properties !== cElem;
		const restrictDefinitions = isCurrentPathElemPropertyType && PROPERTY_TYPES.definitions !== cElem;
		const restrictItems = isCurrentPathElemPropertyType && PROPERTY_TYPES.items !== cElem;
		
		const searchedElemPos = pathPos + (isCurrentPathElemPropertyType ? 1 : 0);
		const searchedElem = path[searchedElemPos];
		
		if (!restrictProperties && entitySchema.properties) {
			const properties = Object.entries(entitySchema.properties);
			const found = properties.find(([key]) => key === searchedElem);
			if (found && isJsonSchema6(found[1])) {
				return resolveRootPath(path, searchedElemPos + 1, found[1], ogEntitySchema)
			}
		}
		
		if (!restrictDefinitions && entitySchema.definitions) {
			const definitions = Object.entries(entitySchema.definitions);
			const found = definitions.find(([key]) => key === searchedElem);
			if (found && isJsonSchema6(found[1])) {
				return resolveRootPath(path, searchedElemPos + 1, found[1], ogEntitySchema)
			}
		}
		
		if (!restrictItems && entitySchema.items) {
			if (Array.isArray(entitySchema.items)) {
				const {items} = entitySchema;
				for (let i = 0; i < items.length; i++) {
					const item = items[i];
					if (isJsonSchema6(item)) {
						const result = resolveRootPath(path, searchedElemPos, item, ogEntitySchema);
						if (result) return result;
					}
				}
			} else if (isJsonSchema6(entitySchema.items)) {
				const result = resolveRootPath(path, searchedElemPos, entitySchema.items, ogEntitySchema);
				if (result) return result;
			}
		}
		if (entitySchema.$ref) {
			const newPath = [...getPathArrayFromRefPath(entitySchema.$ref), ...path.slice(pathPos)];
			return resolveRootPath(newPath, 0, ogEntitySchema, ogEntitySchema)
		}
		
		// if no fitting path was found -> return null
		return null;
	}
}


/*
EXAMPLE:
Schema:
{
  type: "object",
  properties: {
    foo: {
      type: "object",
      properties: {
        bar: {type: "string"}
      }
    },
    baz: {
      type: "array",
      items: {
        type: "object",
        properties: {
          description: {
            "type": "string"
          }
        }
      }
    }
  }
}

^
---|TO|---
v

UISchema:
  foo: {
    bar: {
      "ui:title": "MyTranslation"
    },
  },
  baz: {
    // note the "items" for an array
    items: {
      description: {
        "ui:title": "MyTranslation"
      }
    }
  }
 */

/**
 * 1. Navigate through properties, definitions and items
 * 2. After every navigation -> take the key of an attribute and also append "ui:title" as part of the returned UiSchema
 *    The translation is found by using the path "currentJsonPath" to navigate through the translations object
 *
 * Note: While "properties" and "definitions" are not included as keys, "items" on the contrary is required
 *
 * Reference: https://react-jsonschema-form.readthedocs.io/en/v1.8.1/form-customization/#title-texts
 *
 * Example UiSchema:
 * {
 *  foo: {
 *      bar: {
 *          "ui:title": "MyTranslation"
 *           },
 *       },
 *  baz: {
 *  // note the "items" for an array
 *      items: {
 *          description: {
 *              "ui:title": "My Person4l Description"
 *          }
 *      }
 *   }
 * }
 *
 * @param jsonSchema - json schema is derived from jsonrootschema
 * @param translations - translation object to hold all the translations
 * @param currentJsonPath - path for navigating through the translations object
 * @param selectedLanguage - language abbreviation (e.g. de or en)
 * @return - UiSchema for react-jsonschema-form
 */
export const mapJsonSchemaToUiSchemaForDataForm = (jsonSchema: JSONSchema6, translations: EntityTranslationObject, currentJsonPath: Path, selectedLanguage: string): UiSchema | undefined => {
	let newUiSchemaObject: UiSchema = {};
	
	if (jsonSchema.properties) {
		newUiSchemaObject = {
			...newUiSchemaObject,
			...Object
				.entries(jsonSchema.properties)
				.reduce((acc, [key, value]) => {
					if (isJsonSchema6(value)) {
						acc[key] = mapJsonSchemaToUiSchemaForDataForm(value, translations, [...currentJsonPath, "properties", key], selectedLanguage)
					}
					return acc;
				}, {} as UiSchema)
		}
	}
	
	// Don't check for items if either there is no object or if there is an array with the length of 0
	if (!(!jsonSchema.items || (Array.isArray(jsonSchema.items) && jsonSchema.items.length === 0))) {
		// In case jsonSchema.items is an array take the first element
		const jsonSchemaItems = Array.isArray(jsonSchema.items) ? jsonSchema.items[0] : jsonSchema.items;
		newUiSchemaObject = {
			...newUiSchemaObject,
			items: isJsonSchema6(jsonSchemaItems)
				? mapJsonSchemaToUiSchemaForDataForm(jsonSchemaItems, translations, [...currentJsonPath, "items"], selectedLanguage)
				: undefined
		}
	}
	
	if (jsonSchema.definitions) {
		newUiSchemaObject = {
			...newUiSchemaObject,
			...Object
				.entries(jsonSchema.definitions)
				.reduce((acc, [key, value]) => {
					if (isJsonSchema6(value)) {
						acc[key] = mapJsonSchemaToUiSchemaForDataForm(value, translations, [...currentJsonPath, "definitions", key], selectedLanguage)
					}
					return acc;
				}, {} as UiSchema)
		}
	}
	const result = findTranslationByPath(translations, currentJsonPath);
	if (result && result[selectedLanguage]) {
		newUiSchemaObject = {...newUiSchemaObject, "ui:title": result[selectedLanguage]};
	}
	
	return newUiSchemaObject;
}
