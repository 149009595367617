const en = {
  sessionExpiredHeader: "Session expired",
  sessionExpiredContent: "Your session has expired. Please log in again.",
  toLoginButton: "To login",
  pageCouldNotBeLoaded: "Page could not be loaded",
};

const de = {
  sessionExpiredHeader: "Sitzung abgelaufen",
  sessionExpiredContent: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  toLoginButton: "Zum Login",
  pageCouldNotBeLoaded: "Seite konnte nicht geladen werden.",
};

export default {
  en,de
};