import React, {Suspense} from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import MenuSidebar from '../MenuSidebar/MenuSidebar';
import {Button, Dimmer, Loader, Modal, SemanticICONS, Sidebar} from 'semantic-ui-react';
import AppHeader from '../AppHeader/AppHeader';
import routes from '../routes';
import {default as axios} from 'axios';

import '../../../lib/ace-imports';

import './MainLayoutContainer.css';
import authService from '../../Core/AuthService';
import {Entity} from "../../Core/models";
import AppRoute from "../AppRoute";
import RestClient from "../../../lib/RestClient";
import ErrorBoundary from "../../Common/ErrorBoundary/ErrorBoundary";
import entityTypeListObservableStore from "../../Core/entityTypeListObservableStore";
import {WithTranslation, withTranslation} from "react-i18next";
import {getDisplayName} from "../../DataQuality/entity-util";

interface MainLayoutContainerProps extends RouteComponentProps<{}>, WithTranslation {
}

interface MainLayoutContainerState {
	entities: Entity[];
	sideBarOpen: boolean;
	isMobile: boolean;
	availableEntities: Array<Entity>;
	routes: Array<AppRoute>;
	sessionExpired: boolean;
	sessionExpiredModalClosed: boolean;
	// This is needed, due to i18n not properly working with life cycle functions (prevProps) in react class components as intended
	prevSelectedLanguage: string;
}

class MainLayoutContainer extends React.Component<RouteComponentProps & WithTranslation, MainLayoutContainerState> {
  private entityListSubscription?: string;
  private defaultRoute: string;
  private axiosInterceptorId?: number;
  private axiosInterceptorIdRestClient?: number;

  static isWidthMobile(width: number): boolean {
    return width < 1050;
  }

  constructor(props: RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {
      entities: [],
      prevSelectedLanguage: this.props.i18n.language,
      sideBarOpen: false,
      isMobile: MainLayoutContainer.isWidthMobile(window.innerWidth),
      availableEntities: [],
      routes: routes,
      sessionExpired: false,
      sessionExpiredModalClosed: false
    };

    this.defaultRoute = localStorage.getItem('DEBUG_DEFAULT_ROUTE') || '/data';

    this.onResize = this.onResize.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.closeSideBar = this.closeSideBar.bind(this);
    this.logout = this.logout.bind(this);
  }

  onResize(event: Event) {
    this.setState({
      isMobile: MainLayoutContainer.isWidthMobile(window.innerWidth)
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);

    const handleError = (error: any) => {
      if (error.response && error.response.status === 401) {
        this.onSessionExpire();
      }
      return Promise.reject(error);
    };
    this.axiosInterceptorId = axios.interceptors.response.use(v => v, handleError);
    this.axiosInterceptorIdRestClient = RestClient.axiosInstance().interceptors.response.use(v => v, handleError);
    this.entityListSubscription = entityTypeListObservableStore.subscribe((_, entities) => {
      if(entities) {
        this.setState({entities});
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<MainLayoutContainerProps>, prevState: Readonly<MainLayoutContainerState>, snapshot?: any) {
    if ((this.state.prevSelectedLanguage !== this.props.i18n.language || prevState.entities !== this.state.entities)) {
      this.updateAvailableEntities(this.state.entities);
      if (this.state.prevSelectedLanguage !== this.props.i18n.language) {
        this.setState({prevSelectedLanguage: this.props.i18n.language})
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if(typeof this.axiosInterceptorId !== 'undefined') {
      axios.interceptors.response.eject(this.axiosInterceptorId);
    }
    if(typeof this.axiosInterceptorIdRestClient !== 'undefined') {
      RestClient.axiosInstance().interceptors.response.eject(this.axiosInterceptorIdRestClient);
    }
    if(this.entityListSubscription) {
      entityTypeListObservableStore.unsubscribe(this.entityListSubscription);
    }
  }

  onSessionExpire() {
    this.setState({
      sessionExpired: true
    });
  }

  toggleSideBar(): void {
    this.setState((prevState, props) => {
      return {sideBarOpen: !prevState.sideBarOpen};
    });
  }

  closeSideBar(): void {
    this.setState({sideBarOpen: false});
  }

  logout(): void {
    authService.logout().then(() => this.props.history.push('/')).catch((err) => console.error('Logout failed', err));
  }

  updateAvailableEntities(entities: Entity[]) {
    const dataRoute = routes.find(route => route.path === '/data');
    if (dataRoute && entities && Array.isArray(entities)) {
      dataRoute.subRoutes = entities.map(entity => ({
        path: '/data/' + entity.internalName,
        title: (entity.translations && getDisplayName(entity.translations,this.props.i18n.language)) || entity.displayName,
        icon: entity.icon as SemanticICONS,
        noTranslate: true
      }));
    }
    this.setState({routes: routes});
  }

  render() {
    const {t} = this.props;
    return (
        <Sidebar.Pushable>
          {this.state.sessionExpired && !this.state.sessionExpiredModalClosed && (
              <Modal centered={false} closeOnDimmerClick={false}
                     open={true} size={'small'} closeIcon="close"
                     onClose={() => this.setState({sessionExpiredModalClosed: true})}>
                <Modal.Header content={t("mainLayoutContainer:sessionExpiredHeader")}/>
                <Modal.Content content={t("mainLayoutContainer:sessionExpiredContent")}/>
                <Modal.Actions>
                  <Button primary={true} content={t("mainLayoutContainer:toLoginButton")}
                          onClick={() => this.props.history.push('/login?redirectTo=' + encodeURIComponent(this.props.location.pathname))}/>
                </Modal.Actions>
              </Modal>
          )}
          <MenuSidebar
              routes={this.state.routes}
              open={this.state.sideBarOpen || !this.state.isMobile}
              onNavigate={this.closeSideBar}
              currentUser={authService.principal}
              onLogout={this.logout}
              currentPath={this.props.location.pathname}
          />
          <Sidebar.Pusher className="app-container">
            <Dimmer onClick={this.closeSideBar}
                    active={this.state.sideBarOpen && this.state.isMobile}/>
            <AppHeader
                routes={routes}
                onToggleSideBar={this.toggleSideBar}
                isMobile={this.state.isMobile}
                onLogout={this.logout}
            />
            <main className="app-content">
              <ErrorBoundary errorMessage={t("mainLayoutContainer:pageCouldNotBeLoaded")}>                <Suspense fallback={<Loader active={true}/>}>
                  <Switch>
                    {routes.filter((route) => !((route.disabled != null && route.disabled()) || (route.requiredRole != null && authService.principal?.roles?.indexOf(route.requiredRole) === -1)) ).map((route) => {
                            return (<Route key={route.path} path={route.path} component={route.component}/>);
                      })


                    }
                    <Redirect path="/" to={this.defaultRoute}/> {/* Default Route - Dashboard */}
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            </main>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
  }
}

export default withTranslation()(MainLayoutContainer);
