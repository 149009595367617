/*
 * Shared context for entity types used by navigation menu and entity list page
 */
import {Entity} from "./models";
import EntityApiClient from "../../lib/EntityApiClient";

type EntityTypeListCallback = (error?: Error, entityList?: Entity[]) => void;

class EntityTypeListObservableStore {
    private loading: boolean = false;
    private loaded: boolean = false;
    private entityList: Entity[]  = [];

    private currentSubscriptionId: number = 0;
    private subscriptions: { [id: string]: EntityTypeListCallback } = {};

    subscribe(callback: EntityTypeListCallback): string {
        this.currentSubscriptionId++;
        const subscriptionId = 'sub_' + this.currentSubscriptionId;
        this.subscriptions[subscriptionId] = callback;
        if(this.loaded) {
            callback(undefined, this.entityList);
        }
        // implicitly refresh data when new subscriber registers and currently not waiting for a request to finish
        if(!this.loading) {
            this.refresh();
        }
        return subscriptionId;
    }

    unsubscribe(id: string) {
        if(typeof this.subscriptions[id] !== 'undefined') {
            delete this.subscriptions[id];
        }
    }

    refresh() {
        this.loading = true;
        EntityApiClient.getEntityListSortedByDisplayName().then((response) => {
            this.loaded = true;
            this.loading = false;
            this.entityList = response.data;
            this.publish(undefined, response.data);
        }).catch((error) => {
            console.error('Failed to load entities', error);
            this.publish(error, undefined);
        });
    }

    reset() {
        // e.g. on logout
        this.loaded = false;
        this.entityList = [];
    }

    private publish(error?: Error, entityList?: Entity[]) {
        for(let subscriptionId in this.subscriptions) {
            const subscription = this.subscriptions[subscriptionId];
            subscription(error, entityList);
        }
    }
}

const entityTypeListObservableStore = new EntityTypeListObservableStore();
export default entityTypeListObservableStore;
