import * as React from 'react';
import {FormEvent, SyntheticEvent} from 'react';
import './LoginContainer.css';
import {
    Button,
    Divider,
    Form,
    Grid,
    Image,
    InputOnChangeData,
    Loader,
    Message,
    Segment
} from 'semantic-ui-react';
import {RouteComponentProps, Route, Switch} from 'react-router';
import {default as authService} from '../../Core/AuthService';
import {parse} from 'query-string';
import InfoService from "../../Core/InfoService";
import ResetPasswordContainer from '../ForgotPassword/ResetPasswordContainer';
import { NavLink, Redirect } from 'react-router-dom';
import {default as axios} from 'axios';
import {IdProviderLoginButton} from "../IdProviderLoginButton";
import {SetNewPasswordContainer} from "../ForgotPassword/SetNewPasswordContainer";
import LanguageSelector from "../../Common/LanguageSelector";
import { WithTranslation, withTranslation } from 'react-i18next';

import logo from '../../../logo.png';


interface LoginContainerState {
    submitting: boolean,
    loading: boolean,
    loadingError: boolean,
    email: string,
    password: string,
    error: boolean,
    errorMsg: string,
    environmentName?: string,
    logo?: string,
    defaultMethod: 'LOGIN_EMAIL' | 'LOGIN_OIDC',
    identityProviders: IdentityProviderFrontendOptions[],
    passwordRecoveryAvailable: boolean;
}

interface IdentityProviderFrontendOptions {
    providerId: string;
    displayName: string;
    buttonText?: string;
    color?: string;
    icon?: string;
}

class LoginContainer extends React.Component<RouteComponentProps<{}> & WithTranslation, LoginContainerState> {
    constructor(props: RouteComponentProps<{}> & WithTranslation) {
        super(props);
        this.state = {
            submitting: false,
            loading: true,
            loadingError: false,
            email: '',
            password: '',
            error: false,
            errorMsg: '',
            defaultMethod: 'LOGIN_EMAIL',
            identityProviders: [],
            passwordRecoveryAvailable: false
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.redirectToDefaultPage = this.redirectToDefaultPage.bind(this);
    }

    redirectToDefaultPage () {
        const queryParameters = parse(this.props.location.search);
        if (queryParameters.redirectTo && typeof queryParameters.redirectTo === "string") {
            try {
                const redirectPath = decodeURIComponent(queryParameters.redirectTo);
                this.props.history.push(redirectPath);
                return;
            } catch {
                // when string cannot be decoded, do nothing, redirect to default path in next statement
            }
        }
        this.props.history.push('/data');
    }

    async componentDidMount () {
        await authService.checkIfLoggedIn().then(this.redirectToDefaultPage).catch(() => {
            // user is not logged in - do nothing
        });

        await this.loadConfig();

        const queryParameters = parse(this.props.location.search);
        if(queryParameters.error && typeof queryParameters.error === 'string') {
            if(queryParameters.error === 'account-locked') {
                this.setState({
                    error: true, errorMsg: 'account_locked'
                });
            } else if(queryParameters.error === 'oauth-error') {
                this.setState({
                    error: true, errorMsg: 'oauth_error'
                });
            } else {
                this.setState({
                    error: true, errorMsg: 'bad_credentials'
                });
            }
            this.props.history.replace({pathname: this.props.location.pathname, search: ''});
        }

    }

    async loadConfig() {
        this.setState({
            loading: true,
            loadingError: false
        });
        try {
            const environmentInfo = await InfoService.getEnvironmentInfo() as any;
            const loginOptions = await axios.get('./login/options') as any;
            const idProviders = loginOptions?.data?.identityProviders || [];
            const passwordRecoveryAvailable = loginOptions?.data?.passwordRecoveryAvailable || false;

            this.setState({
                environmentName: environmentInfo?.name,
                logo: environmentInfo?.logo,
                identityProviders: idProviders,
                passwordRecoveryAvailable: passwordRecoveryAvailable,
                defaultMethod: idProviders.length > 0 ? 'LOGIN_OIDC' : 'LOGIN_EMAIL',
                loading: false
            });
        } catch(e) {
            this.setState({
                loading: false,
                loadingError: true
            });
        }
    }

    onFormSubmit(event: FormEvent<HTMLElement>) {
        event.preventDefault();
        if (this.state.submitting) {
            return;
        }
        this.setState({
            submitting: true
        });
        authService.login({
            email: this.state.email,
            password: this.state.password
        })
            .then(this.redirectToDefaultPage)
            .catch(error => {
                this.setState({
                    submitting: false,
                    password: '',
                    error: true,
                    errorMsg: error
                });
            });
    }

    onChangeEmail(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ email: data.value });
    }

    onChangePassword(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ password: data.value });
    }

    renderLoginForm() {
        const {t} = this.props;
        return (<>
            <Form onSubmit={this.onFormSubmit} error={this.state.error}>
                <Form.Input
                  label={t('auth:login.login_form_email')}
                  icon="user"
                  name="email"
                  type="email"
                  autoFocus
                  required={true}
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  disabled={this.state.submitting}
                />
                <Form.Input
                  label={t('auth:login.login_form_password')}
                  icon="key"
                  name="password"
                  type="password"
                  required={true}
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  disabled={this.state.submitting}
                />
                <Form.Button type="submit"  fluid primary loading={this.state.submitting} content={t('auth:login.login_form_submit')}/>
                {this.state.error && (
                  <Message
                    style={{marginBottom: '1em'}}
                    error={true}
                    icon="warning"
                    header={t('auth:login.login_failed')}
                    content={t('auth:login.error.' + (this.state.errorMsg || 'unspecified'))}
                  />
                )}
            </Form>
            <br />
            {this.state.identityProviders.length > 0 && (<div style={{textAlign: 'center'}}>
                <Button basic color="blue" className="link" onClick={(ev) => {
                    ev.preventDefault();
                    this.setState({error: false, errorMsg: ''});
                    this.props.history.push(`/login/id-provider${this.props.location.search}`);
                }}>{t('auth:login.link_login_with_id_provider')}</Button>
            </div>)}
            <div style={{textAlign: 'center'}}>
                {this.state.passwordRecoveryAvailable && <NavLink to={this.props.match.path + '/reset-password'}>
                    {t('auth:login.link_forgot_password')}
                </NavLink>}
            </div>
        </>);
    }

    renderOidcLogin() {
        const {t} = this.props;
        return (<>
            {this.state.error && (
              <Message
                style={{marginBottom: '1em'}}
                error={true}
                icon="warning"
                header={t('auth:login.login_failed')}
                content={t('auth:login.error.' +  (this.state.errorMsg || 'unspecified'))}
              />
            )}
            {this.state.identityProviders.map((provider, idx) => {
                return <IdProviderLoginButton provider={provider} key={`provider_${idx}`} fluid className="login-page__identity-provider-button"/>
            })}
            <Divider />
            <Segment style={{textAlign: 'center'}}>
                <Button basic color="blue" className="link" onClick={(ev) => {
                    ev.preventDefault();
                    this.setState({error: false, errorMsg: ''});
                    this.props.history.push(`/login/email${this.props.location.search}`);
                }}>{t('auth:login.link_login_with_email')}</Button>
            </Segment>
        </>);
    }

    render() {
        const {t} = this.props;
        if(this.state.loading) {
            return <Loader active={true} />;
        }
        if(this.state.loadingError) {
            return (
              <Grid className="login-grid" centered={true} verticalAlign="middle">
                  <Grid.Column widescreen="3" mobile="15" tablet="8" computer="5" largeScreen="4" style={{textAlign:'center'}}>
                      {t('auth:connection_error')} <br /><br />
                      <Form.Button primary content={t('auth:connection_error_retry')} icon="refresh" onClick={() => this.loadConfig()}/>
                  </Grid.Column>
              </Grid>);
        }
        return (<>
            <div className="login-page__language-selector">
               <LanguageSelector />
            </div>
            <Grid className="login-grid" centered={true} verticalAlign="middle">
                <Grid.Column widescreen="3" mobile="15" tablet="8" computer="5" largeScreen="4">

                    {this.state && this.state.logo && this.state.logo.length > 0 ? (
                            <>
                                <Image src={this.state.logo} alt="CAS RDH Logo" spaced={false} size="medium" centered={true}/>

                            </>) :
                        !this.state.loading ?
                            <>
                                <Image src={logo} alt="CAS RDH Logo" spaced={false} size="medium" centered={true}/>
                            </>
                            : <></>
                    }

                    {this.state && this.state.environmentName ? (
                        <h2 style={{textAlign:"center", marginBottom: '1em', marginTop: '0.5em'}}> {this.state.environmentName} </h2>) : (<></>)
                    }

                    <Grid columns="16">
                        <Grid.Column width="16">
                        <Switch>
                            {this.state.passwordRecoveryAvailable && <Route path={this.props.match.path + '/reset-password/:id/:token'} component={SetNewPasswordContainer} />}
                            {this.state.passwordRecoveryAvailable && <Route path={this.props.match.path + '/reset-password'} render={() => <ResetPasswordContainer />} />}
                            {!this.state.passwordRecoveryAvailable && <Redirect path={this.props.match.path + '/reset-password'} to={this.props.match.path} />}

                            <Route path={this.props.match.path + '/id-provider'} render={() => this.renderOidcLogin()}  />
                            <Route path={this.props.match.path + '/email'} render={() => this.renderLoginForm()} />
                            <Route path={this.props.match.path}>
                                {this.state.defaultMethod === 'LOGIN_EMAIL' && this.renderLoginForm()}
                                {this.state.defaultMethod === 'LOGIN_OIDC' && this.renderOidcLogin()}
                            </Route>
                        </Switch>
                        </Grid.Column>
                    </Grid>
                    <Grid columns="16">
                        <Grid.Column width="6" />
                        <Grid.Column width="4" textAlign={"center"}>
                            {this.state && this.state.logo && this.state.logo.length > 0 && (
                                <Image src={logo} alt="CAS RDH Logo" spaced={false} size="medium" centered={true}/>
                            )}
                        </Grid.Column>
                        <Grid.Column width="6" />
                    </Grid>
                </Grid.Column>
            </Grid>
        </>);
    }
}

export default withTranslation()(LoginContainer);