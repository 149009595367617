const en = {
    header: {
        title: "Entity types",
        subTitle: "Specify the types of data to be managed by the application.",
        buttonNew: "New"
    },
    table: {
        headerCell: {
            apiName: "API name",
            name: "Name",
            numberOfDocuments: "Number of documents"
        },
        popupWarning: "It is recommended to create a unique index for the primary key fields of entity.",
        buttonApi: "API",
        buttonEdit: "Edit"
    },

    apiModal: {
        buttonClose: 'Close',
        authorizationNotice: 'All API calls you perform with "Try it out" are performed on real data and use your current user & permissions.'
    },

    deleteModal: {
        header: 'Delete Entity Type "{{name}}"',
        text: 'Do you really want to delete the entity type <strong>{{displayName}}</strong> (API name: <strong>{{internalName}}</strong>)?<br />' +
          'This action cannot be undone.',

        checkboxDeleteDocuments: 'Also delete all documents',
        confirmationText: 'To confirm this action, please enter the API name of the entity type: ',

        buttonCancel: 'Cancel',
        buttonDelete: 'Delete entity type'
    },

    deleteAllDocumentsModal: {
        header: 'Delete all documents',
        text: 'Do you really want to delete all documents of entity type <strong>{{displayName}}</strong> (API name: <strong>{{internalName}}</strong>)?<br />' +
          'This action cannot be undone.',

        confirmationText: 'To confirm this action, please enter the API name of the entity type: ',
        createChanges: 'Create DELETE-Changes.',

        buttonCancel: 'Cancel',
        buttonDelete: 'Delete Documents',

        successMessage: 'Deleted all documents',
        error: {
            missingPermissions: 'You are not authorized to perform this action',
            notEnabled: 'Deleting all documents is disabled for this entity type.',
            common: 'Could not delete all documents.'
        }
    },

    deleteDocumentsByQueryFilterModal: {
        header: "Delete multiple documents",
        text: 'Do you really want to delete all documents of the <strong>{{displayName}}</strong> entity type (API name: <strong>{{internalName}}</strong>) that match the selected criteria?<br/>' +
          'The number currently amounts to <strong>{{amountOfDeletableDocuments}}</strong> out of a total of <strong>{{amountOfAllDocuments}}</strong> documents.<br/>' +
          'This action cannot be undone.',

        confirmationText: 'To confirm this action, please enter the API name of the entity type: ',

        contentText: 'Specify which documents should be deleted. You have can either write a query ' +
          'or fill in the corresponding fields in the filter section. Note that the filter only selects documents ' +
          'that exactly match all fields entered.',
        contentHint: 'Click "Continue" to execute the query or filter and display the number of documents to be deleted.<br/>' +
          'No documents are deleted yet!',

        buttonCancel: 'Cancel',
        buttonContinue: 'Continue',
        buttonDelete: "Delete documents",
        buttonDeleteCertainDocuments: 'Delete multiple documents',

        successMessage: "{{amountOfDeletableDocuments}} documents deleted",
        warning: {
            noDocumentFound: "No documents found"
        },
        error: {
            missingPermissions: 'Insufficient permissions',
            notEnabled: 'Function not enabled',
            noJsonObject: "Query can't parsed to JSON",
            common: 'Documents could not be deleted.',
            unexpectedError: "Unexpected error"
        },

        horizontalAndDivider: "And"
    },

    detailPage: {
        header: {
            title: "Entity type \"{{display_name}}\"",
            buttonBack: "Back",
            buttonEdit: "Edit",
        },
        general: {
            title: "General",
            guiName: "GUI name",
            apiName: "API name",
            collection: "Collection",
            linkToDocumentation: "Link to documentation",
            description: "Description"
        },
        databaseStatistics: {
            title: "Database statistics",
            numberOfDocuments: "Number of documents",
            sizeOfUncompressedDocuments: "Size of documents (uncompressed)",
            sizeOfDataCarriedDocuments: "Size of documents (on disk)",
            averageDocumentSize: "Average document size",
            sizeOfAllIndices: "Size of all indexes"
        },
        indices: {
            title: "Indexes",
            buttonNewIndex: "New index",
            accordion: {
                fields: "Fields:",
                size: "Size:",
                buttonDelete: "Delete"
            },
            modalConfirmation: {
                title: "Really delete index {{index_name}}?",
            },
            modalNewIndex: {
                title: "New index",
                description: "Specify a unique name for the index and the fields to be indexed.\n" +
                    "For each field to be indexed, you specify whether the contents should be indexed in ascending (1) or descending (-1) order." +
                    " This is only relevant for sorting operations in the case of indexes composed of several fields.\n" +
                    "Only fields containing short texts (up to 100 characters) or numeric values should be indexed.",
                indexName: "Index name",
                fields: "Fields",
                cancel: "Cancel",
                create: "Create"
            },
            indexRecommendationHeader: 'Recommendation',
            messageMissingUniqueIndex: {
                description: "Create a unique index for the primary key fields of this entity.",
                buttonApply: "Apply"
            },
            messageWrongKeysUniqueIndex: {
                description: 'The primary key index "{{indexName}}" should be marked as unique.',
            },
            deleteError: 'Index could not be deleted',
            deleteSuccess: 'Index {{name}} deleted.',
            createSuccess: 'Index created.',
            updateSuccess: 'Index updated.',

            createErrorPrefix: 'Index could not be created:',
            createError: {
                fields_already_indexed: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) There is already another index with the specified fields.',
                name_already_used: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) There is already another index with the same name.',
                unique_idx_duplicates: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) A unique index could not be created, because the collection already contains duplicates for the specified fields.',
                unexpected: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) Unexpected error.'
            },

            inProgressModal: {
                header: 'Index operation in progress',
                text: 'Applying changes to indexes. Since this is a large collection, the process may take a few moments.'
            }
        },
        qualityGatesLog: {
            title: "Quality Gates log",
            buttonShowLog: "Show log"
        },
        otherFunctions: {
            title: "Other functions",
            hintDeleteAllDocuments: "To delete multiple documents, first activate this function in the entity configuration",
            buttonDeleteAllDocuments: "Delete all documents"
        }
    },
    editPage: {
        alert: "Leave page without saving changes?",
        warning: "Unsaved changes exist",
        menuItem: {
            general: "General",
            dataModel: "Data model",
            filterView: "FilterView",
            views: "Views",
            qualityGates: "Quality Gates",
            translationTable: "Translations",
            json: "JSON",
        },
        saving: {
            successHeader: 'Changes saved',
            successText: 'Entity configuration saved.',
            error: {
                header: 'Error saving entity configuration',
                apiNameMissing: 'API name is mandatory.',
                displayNameMissing: 'GUI name is mandatory.',
                primaryKeyMissing: 'Primary key is mandatory (see tab data model).',
                schemaNotObject: 'The provided schema does not describe a JSON object',
                schemaInvalidJson: 'The provided schema cannot be parsed',
                invalidQualityGate: 'All fields in the Quality Gates must be filled in.',
                duplicateQualityGateId: 'There is already a Quality Gate with this ID.',
                noResponse: 'No response from server'
            }
        },
        general: {
            header: {
                titleEdit: "Edit entity type \"{{display_name}}\"",
                titleCreate: "Create entity type \"{{display_name}}\"",
                buttonBack: "Back",
                buttonSave: "Save"
            },
            guiName: "GUI name",
            apiName: "API name",
            icon: "Icon",
            buttonIcon: "Choose Icon",
            apiOptions: "API options",
            updateMode: "Update mode V1",
            cboxOldAuthorizationModelForUpsert: "Use old authorization model for V1-Upsert",
            cboxAllowBulkUpsert: "Allow Bulk-Upsert",
            maxPageSizeGetApi: "Maximum Page-Size for GET API",
            cboxAllowDeletingAllDocuments: "Allow deleting multiple documents",
            historyHeader: 'Change history',
            cboxAllowDeltaLogging: "Enable change history",
            cboxChangesSaveOldDocument: "Save old document",
            cboxChangesSaveNewDocument: "Save new document",
            cboxChangesRetentionTime: "Retention time (hours)",
            cboxChangesRetentionCount: "Retention count",
            retentionTimeHint: "Number of hours after which changes should be removed from the history collection. Set this value to 0 to disable automatic deletion.",

            maxPageSizeGetApiHint: "Maximum number of documents that can be retrieved via the GET API in one request. Default setting is 2,000, maximum 1,0000. If the individual documents of this entity are particularly large, the value should not be set too high.",

            exportImportHeader: 'Export / Import',
            cboxAllowExcelImport: "Enable import of xlsx files",
            cboxAllowExcelExport: "Enable export of xlsx files",
            exportImportInfo: "Allows users to export or import Spreadsheet files through the user interface. Currently array properties and subentities are not supported.",

            importThousandsSeparator: "Thousands separator",
            importDecimalSeparator: "Decimal separator",
            importMinimumFractionDigits: "Minimum number of decimals",
            importDefaultSeparatorOnNumberToStringConversion: "Import: Number format",

            importNumberFormatHint: "If columns with numerical values are imported into an RDH text field, " +
              "they will be formatted according to the settings defined here. " +
              "The number format can be customized for individual fields in the schema. " +
              "Key fields are excluded from this rule.",

            importSeparatorOptions: {
                none: "None",
                comma: "Comma",
                dot: "Dot"
            },

            iconPicker: {
                selectIcon: "Select Icon",
                buttonCancel: "Cancel",
            },
            linkToDocumentation: "Link to documentation",
            description: "Description"
        },
        filterView: {
            description: "When you configure a view in this tab, a filter form is displayed above the entity data table which allows searching for documents based on your custom input fields. " +
              "By default, search inputs are treated as non-exact and case insensitive. In case you want to search for an exact match, add the field to the \"Fields with exact match\" list.",
            filterForm: "Filter Form",
            fieldsWithExactMatch: "Fields with exact match",
            preview: "Preview",
            buttonReset: "Reset",
        },
        dataModel: {
            scheme: "Schema",
            descriptionSchema: "Use JSON Schema draft v4 or v6",
            buttonFormat: "Format",
            structure: "Structure",
            key: "Primary Key",
            keyHint: "Which field - or combination of fields - clearly identifies a single document?",
            autoGeneratedFields: "Automatically filled fields",
            autoGeneratedFieldsHint: "Fields to be filled in automatically by RDH when a document is created or updated. ",
            tableColumns: "Table columns",
            tableColumnsHint: "If specified, the following fields are displayed as columns in the general data table view instead of the keys.",
            subEntities: "Subentities",
            subEntitiesHint: "Subentities are array fields in which each object can be uniquely identified via key fields, so that only the supplied items of the subentity are modified during a PATCH request.",
            errorFieldNotFound: "Field {{property_path}} not found in schema",
            errorFieldUnexpectedType: "Field {{property_path}} does not match the expected data type \"{{expected_type}}\" ",
            warningTimestampAsKeyField: "It is not recommended to use a timestamp as the only key field",
            errorInvalidSchemaDefinition: "Invalid schema: Definition object should not contain a definition with the internal name of the entity",
            errorInvalidSchemaMainLayer: "Invalid schema: Must describe an object/document on the root level (type = object)",
            errorInvalidSchema: "Invalid schema: ",
            errorInvalidJson: "Invalid JSON",
        },
        views: {
            standardEditingView: "Default editing view",
            alertDuplicate: "There is already a view with the view-ID in this entity.",
            formOptionGenericForm: "Generic form",
            formOptionJsonEditor: "JSON Editor",
            multipleProcessing: "Mass update view",
            deactivated: "Disabled",
            availableViews: "Available views",
            genericForm: "Generic form",
            viewTypeEditView: "Edit view",
            buttonEdit: "Edit",
            buttonDelete: "Delete",
            jsonEditor: "JSON Editor",
            buttonConfirm: "Yes, remove",
            removeViewConfirmation: "Do you really want to delete the view {{view_name}} ({{view_id}})?",
            saveForEffectiveChange: "The change becomes effective when the entity is saved.",
            buttonNewView: "New view",
            createView: "Create view",
            viewId: "View ID",
            name: "Name",
            type: "Type",
            buttonCancel: "Cancel",
            buttonAdd: "Add",
            copyDocuments: "Copy documents",
            copyDocumentsHint: "If this function is active, a copy button is displayed next to the edit button in the table, which can be used to create a new document as a copy of the selected one.",
            activateCloneButton: "Enable clone button",
            advancedSettings: "Advanced settings",
            predefinedValuesForCopy: "Predefined values for copy (JSON object)",
            viewNotFound: "View not found",
            editView: "Edit view {{view_name}} ({{view_id}})",
            backToViewList: "Back to view list",
            componentList: "Component list",
            preview: "Preview",
            exampleData: "Example data",
        },
        qualityGates: {
            description: "Quality Gates update all documents matching the associated query.<br/>" +
              "For example, a query can be formulated which finds all documents where a certain field is empty.<br/>" +
              "All these documents are then updated with the values from the update document. <br/><br/>" +
              "The query must be a MongoDB query, the update document a JSON object containing the fields to be updated.<br/>" +
              "All field names must be preceded by a \"data.\" if you want to reference fields from your schema / data model.<br /><br />" +
              "Please note that Quality Gates currently do not update the change timestamp as well as the change history by default.",
            executionDescription1: "The Quality Gates can be executed automatically at a certain interval. For this purpose, an execution interval must be selected.<br/>" +
              "Please note that the execution time depends on the amount of data and configuration of the Quality Gates and therefore cannot always be kept exactly.<br/>",
            executionDescription2: "In addition, the execution of the Quality Gates for this entity type can be controlled via the API endpoint ",
            executionDescription2_1: " .",
            dropdownLabel: "Execution interval",
            updateDocument: "Update document",
            insertExample: "Insert example",
            buttonTest: "Test Quality Gates",
            buttonTestPopup: "The entity must first be saved so that quality gates can be tested.",
            intervalOptions: {
                "neverExecute": "Never execute",
                "30Seconds": "30 seconds",
                "60Seconds": "60 seconds",
                "300Seconds": "5 minutes",
                "600Seconds": "10 minutes",
            },
            modalTest: {
                loading: "Loading...",
                result: "Result",
                description: "The test execution checks whether the query and update strings can be parsed.",
                description1: "The query object is then used to execute a .count() in the collection of the entity.",
                table: {
                    headerCellDuration: "Duration (count)",
                    headerCellDocuments: "Documents",
                },
                hint: "When the Quality Gates are actually executed, a longer execution time is expected due to the updates which are skipped in test mode.",
                hint1: "Using these values, however, it may be possible to test optimizations using indices.",
                errorDuringExecution: "Error during execution"
            }
        },
        translationTable: {
            header: "Translations",
            description: "Add translations to this entity in various languages.",
            languageSelectionPlaceholder: "Languages...",
            table: {
                entityName: "Entity name",
                bucketName: "Bucket name",
                properties: "Properties",
                iconElaboration: {
                    archive: "Entity",
                    bookmark: "Reference",
                    edit: "Field",
                    book: "Definition"
                }
            }
        },
        json: {
            description: "JSON representation of the complete entity configuration. Can be used to transfer the configuration to another RDH environment.",
        }
    }
};

const de = {
    header: {
        title: "Entitätstypen",
        subTitle: "Legen Sie die Datentypen fest, die von der Anwendung verwaltet werden sollen.",
        buttonNew: "Neu"
    },
    table: {
        headerCell: {
            apiName: "API Name",
            name: "Name",
            numberOfDocuments: "Anzahl Dokumente"
        },
        popupWarning: "Es wird empfohlen, einen unique Index für diese Entität anzulegen.",
        buttonApi: "API",
        buttonEdit: "Bearbeiten"
    },

    apiModal: {
        buttonClose: 'Schließen',
        authorizationNotice: 'Alle API Aufrufe, die Sie hier mit "Try it out" ausführen, werden mit Ihren Berechtigungen auf den echten Daten ausgeführt.'
    },

    deleteModal: {
        header: 'Entitätstyp {{name}} löschen',
        text: 'Möchten Sie den Entitätstypen <strong>{{displayName}}</strong> (API Name: <strong>{{internalName}}</strong>) wirklich löschen?<br />' +
          'Dieser Vorgang kann nicht rückgängig gemacht werden.',

        checkboxDeleteDocuments: 'Auch alle Dokumente löschen',
        confirmationText: 'Geben Sie zur Bestätigung den API Namen des Entitätstyps ein:',

        buttonCancel: 'Abbrechen',
        buttonDelete: 'Entität löschen'
    },


    deleteAllDocumentsModal: {
        header: 'Alle Dokumente löschen',
        text: 'Möchten Sie wirklich alle Dokumente des Entitätstyps <strong>{{displayName}}</strong> (API Name: <strong>{{internalName}}</strong>) löschen?<br />' +
          'Diese Aktion kann nicht rückgängig gemacht werden.',

        confirmationText: 'Geben Sie zur Bestätigung den API-Namen des Entitätstyps ein: ',
        createChanges: 'DELETE-Changes erzeugen.',

        buttonCancel: 'Abbrechen',
        buttonDelete: 'Dokumente löschen',

        successMessage: 'Alle Dokumente gelöscht',
        error: {
            missingPermissions: 'Unzureichende Berechtigungen',
            notEnabled: 'Funktion nicht aktiviert',
            common: 'Dokumente konnten nicht gelöscht werden.'
        }
    },

    deleteDocumentsByQueryFilterModal: {
        header: "Mehrere Dokumente löschen",
        text: 'Möchten Sie wirklich alle Dokumente des Entitätstyps <strong>{{displayName}}</strong> (API Name: <strong>{{internalName}}</strong>) löschen, die den gewählten Kriterien entsprechen?<br/>' +
          'Die Anzahl beläuft sich derzeit auf <strong>{{amountOfDeletableDocuments}}</strong> von insgesamt <strong>{{amountOfAllDocuments}}</strong> Dokumenten.<br/>' +
          'Diese Aktion kann nicht rückgängig gemacht werden.',

        confirmationText: 'Geben Sie zur Bestätigung den API-Namen des Entitätstyps ein: ',

        contentText: 'Geben Sie an, welche Dokumente gelöscht werden sollen. Sie können entweder eine Query schreiben ' +
          'oder entsprechende Felder in den Filteroptionen ausfüllen. Beachten Sie, dass beim Filter nur Dokumente ' +
          'gelöscht werden, die mit allen ausgefüllten Feldern exakt übereinstimmen.',
        contentHint: 'Klicken Sie auf "Fortfahren", um die Query oder den Filter auszuführen und die Anzahl an löschenden Dokumente anzuzeigen.<br/>' +
          'Hierbei werden noch keine Dokumente gelöscht!',

        buttonCancel: 'Abbrechen',
        buttonContinue: 'Fortfahren',
        buttonDelete: "Dokumente löschen",
        buttonDeleteCertainDocuments: 'Mehrfachlöschung',

        successMessage: "{{amountOfDeletableDocuments}} Dokumente gelöscht",
        warning: {
            noDocumentFound: "Keine Dokumente gefunden"
        },
        error: {
            missingPermissions: 'Unzureichende Berechtigungen',
            notEnabled: 'Funktion nicht aktiviert',
            noJsonObject: "Query kann nicht zu JSON geparst werden",
            common: 'Dokumente konnten nicht gelöscht werden.',
            unexpectedError: "Unerwarteter Fehler"
        },

        horizontalAndDivider: "Und"
    },

    detailPage: {
        header: {
            title: "Entitätstyp \"{{display_name}}\"",
            buttonBack: "Zurück",
            buttonEdit: "Bearbeiten",
        },
        general: {
            title: "Allgemein",
            guiName: "GUI Name",
            apiName: "API Name",
            collection: "Collection",
            linkToDocumentation: "Link zur Dokumentation",
            description: "Beschreibung"
        },
        databaseStatistics: {
            title: "Datenbank-Statistik",
            numberOfDocuments: "Anzahl der Dokumente",
            sizeOfUncompressedDocuments: "Größe der Dokumente (unkomprimiert)",
            sizeOfDataCarriedDocuments: "Größe der Dokumente (auf Datenträger)",
            averageDocumentSize: "Durchschnittliche Dokumentengröße",
            sizeOfAllIndices: "Größe aller Indizes"
        },
        indices: {
            title: "Indizes",
            buttonNewIndex: "Neuer Index",
            accordion: {
                fields: "Felder:",
                size: "Größe:",
                buttonDelete: "Löschen"
            },
            modalConfirmation: {
                title: "Index {{index_name}} wirklich löschen?",
            },
            modalNewIndex: {
                title: "Neuer Index",
                description: "Geben Sie einen eindeutigen Namen für den Index sowie die zu indizierenden Felder an.\n" +
                    "Für jedes zu indizierende Feld wird angegeben, ob die Inhalte aufsteigend (1) oder absteigend (-1) indiziert werden sollen." +
                    "Dies ist nur bei aus mehreren Feldern zusammengesetzten Indizes für Sortieroperationen relevant.\n" +
                    "Es sollten nur Felder indiziert werden, die kurze Texte (bis 100 Zeichen) oder numerische Werte enthalten.",
                indexName: "Index-Name",
                fields: "Felder",
                cancel: "Abbrechen",
                create: "Erstellen"
            },
            indexRecommendationHeader: 'Hinweis',
            messageMissingUniqueIndex: {
                description: 'Erstellen Sie einen unique Index für die Schlüsselfelder dieser Entität.',
                buttonApply: "Anwenden",
            },
            messageWrongKeysUniqueIndex: {
                description: 'Der Primary Key Index "{{indexName}}" sollte als unique markiert werden.'
            },
            deleteError: 'Index konnte nicht gelöscht werden',
            deleteSuccess: 'Index {{name}} gelöscht.',
            createSuccess: 'Index erstellt.',
            updateSuccess: 'Index aktualisiert.',
            suggestionSuccess: 'Primary Key Index angelegt.',

            createErrorPrefix: 'Index konnte nicht erstellt werden:',
            createError: {
                fields_already_indexed: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) Es existiert bereits ein anderer Index mit identischen Feldern.',
                name_already_used: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) Es existiert bereits ein anderer Index mit dem selben Namen.',
                unique_idx_duplicates: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) Es konnte kein Unique Index angelegt werden, da die Collection für die angegebenen Felder bereits Duplikate enthält.',
                unexpected: '$t(entityConfiguration:detailPage.indices.createErrorPrefix) Unerwarteter Fehler aufgetreten.'
            },

            inProgressModal: {
                header: 'Index-Anpassung wird durchgeführt',
                text: 'Da es sich um eine größere Collection handelt, kann dieser Vorgang etwas Zeit in Anspruch nehmen.'
            }
        },
        qualityGatesLog: {
            title: "Quality Gates Log",
            buttonShowLog: "Log anzeigen"
        },
        otherFunctions: {
            title: "Andere Funktionen",
            hintDeleteAllDocuments: "Um mehrere Dokumente zu löschen, aktivieren Sie zunächst diese Funktion in der Entitätenkonfiguration",
            buttonDeleteAllDocuments: "Alle Dokumente löschen"
        }
    },
    editPage: {
        alert: "Seite verlassen ohne Änderungen zu speichern?",
        warning: "Ungespeicherte Änderungen vorhanden",
        menuItem: {
            general: "Allgemein",
            dataModel: "Datenmodell",
            filterView: "FilterView",
            views: "Views",
            qualityGates: "Quality Gates",
            translationTable: "Übersetzungen",
            json: "JSON",
        },
        saving: {
            successHeader: 'Änderungen gespeichert',
            successText: 'Entität wurde gespeichert.',
            error: {
                header: 'Fehler beim Speichern',
                apiNameMissing: 'API Name muss ausgefüllt werden.',
                displayNameMissing: 'GUI Name muss ausgefüllt werden.',
                primaryKeyMissing: 'Im Abschnitt Datenmodell muss mindestens ein Schlüssel angegeben werden.',
                schemaNotObject: 'Das angegebene Schema beschreibt kein JSON Objekt',
                schemaInvalidJson: 'Das angegebene Schema ist kein gültiges JSON Dokument',
                invalidQualityGate: 'Es müssen alle Felder bei den Quality Gates ausgefüllt sein.',
                duplicateQualityGateId: 'Es existiert bereits eine Quality Gate mit dieser ID.',
                noResponse: 'Keine Antwort vom Server.'
            }
        },
        general: {
            header: {
                titleEdit: "Entitätstyp \"{{display_name}}\" bearbeiten",
                titleCreate: "Entitätstyp \"{{display_name}}\" erstellen",
                buttonBack: "Zurück",
                buttonSave: "Speichern"
            },
            guiName: "GUI Name",
            apiName: "API Name",
            icon: "Icon",
            buttonIcon: "Icon wählen",
            apiOptions: "API-Optionen",
            updateMode: "Updatemodus V1",
            cboxOldAuthorizationModelForUpsert: "Altes Berechtigungsmodell für V1-Upsert verwenden",
            cboxAllowBulkUpsert: "Bulk-Upsert erlauben",
            maxPageSizeGetApi: "Maximale Page-Size für GET API",
            cboxAllowDeletingAllDocuments: "Mehrere Dokumente löschen erlauben",
            historyHeader: 'Änderungsverlauf',
            cboxAllowDeltaLogging: "Änderungen protokollieren",
            cboxChangesSaveOldDocument: "Altes Dokument speichern",
            cboxChangesSaveNewDocument: "Neues Dokument speichern",
            cboxChangesRetentionTime: "Retention-Time (Stunden)",
            cboxChangesRetentionCount: "Max. Anzahl Datensätze",
            retentionTimeHint: "Anzahl der Stunden, nach denen Änderungen aus der Historie entfernt werden sollen. Setzen Sie diesen Wert auf 0, um die automatische Löschung zu deaktivieren.",

            maxPageSizeGetApiHint: "Maximale Anzahl von Dokumenten, die über die GET API in einem Request abgerufen werden können. Standardeinstellung ist 2.000, maximum 10.000. Sind die einzelnen Dokumente dieser Entität besonders groß, sollte der Wert nicht zu hoch gewählt werden.",

            exportImportHeader: 'Export / Import',
            cboxAllowExcelImport: "Import von XLSX-Dateien aktivieren",
            cboxAllowExcelExport: "Export von XLSX-Dateien aktivieren",
            exportImportInfo: "Ermöglicht es Benutzern, xlsx Dateien über die Benutzeroberfläche zu exportieren und zu importieren. Aktuell werden Array-Eigenschaften und Subentitäten nicht unterstützt.",

            importThousandsSeparator: "Tausendertrennzeichen",
            importDecimalSeparator: "Dezimaltrennzeichen",
            importMinimumFractionDigits: "Mindestanzahl Nachkommastellen",

            importDefaultSeparatorOnNumberToStringConversion: "Import: Zahlenformat",

            importNumberFormatHint: "Werden Spalten mit numerischen Werten in ein RDH-Textfeld importiert, werden diese den " +
              "hier festgelegten Einstellungen entsprechend formatiert. " +
              "Das Zahlenformat kann für einzelne Felder im Schema angepasst werden. Schlüsselfelder sind hiervon ausgenommen.",

            importSeparatorOptions: {
                none: "Keine",
                comma: "Komma",
                dot: "Punkt"
            },

            iconPicker: {
                selectIcon: "Icon wählen",
                buttonCancel: "Abbrechen",
            },
            linkToDocumentation: "Link zur Dokumentation",
            description: "Beschreibung"
        },
        filterView: {
            description: "Hier kann eine View definiert werden, die in der Datenpflege oberhalb der Tabelle angezeigt wird, um Suchkriterien/Filter auszuwählen.",
            filterForm: "Filter-Formular",
            fieldsWithExactMatch: "Felder mit exakter Übereinstimmung",
            preview: "Vorschau",
            buttonReset: "Zurücksetzen",
        },
        dataModel: {
            scheme: "Schema",
            descriptionSchema: "Bitte JSON Schema draft v4 oder v6 verwenden",
            buttonFormat: "Formatieren",
            structure: "Struktur",
            key: "Schlüssel",
            keyHint: "Welches Feld - oder welche Kombination aus Feldern - identifiziert ein einzelnes Dokument eindeutig?",
            autoGeneratedFields: "Automatisch gefüllte Felder",
            autoGeneratedFieldsHint: "Felder, die vom RDH automatisch ausgefüllt werden sollen, wenn ein Dokument angelegt oder aktualisiert wird.",
            tableColumns: "Tabellen-Spalten",
            tableColumnsHint: "Wenn angegeben, werden statt der Schlüssel folgende Felder als Spalten in der allgemeinen Datenpflegeansicht angezeigt.",
            subEntities: "Unterentitäten",
            subEntitiesHint: "Unterentitäten sind Array-Felder, in der jedes Objekt über Schlüsselfelder eindeutig identifiziert werden kann, so dass bei einem PATCH Request nur die mitgelieferten Items der Unterentität modifiziert werden.",
            errorFieldNotFound: "Feld {{property_path}} nicht in Schema gefunden",
            errorFieldUnexpectedType: "Feld {{property_path}} entspricht nicht dem erwarteten Datentyp \"{{expected_type}}\" ",
            warningTimestampAsKeyField: "Es wird nicht empfohlen, einen Zeitstempel als alleiniges Schlüsselfeld zu verwenden",
            errorSchemaDefinition: "Ungültiges Schema: Definitions-Objekt sollte keine Definition mit dem internen Namen der Entität enthalten",
            errorSchemaMainLayer: "Ungültiges Schema: Muss auf der Hauptebene ein Objekt/Dokument beschreiben (type = object)",
            errorInvalidSchema: "Ungültiges Schema: ",
            errorInvalidJson: "Ungültiges JSON",
        },
        views: {
            standardEditingView: "Standard Bearbeitungsansicht",
            alertDuplicate: "Es existiert in dieser Entität bereits eine View mit der View-ID.",
            formOptionGenericForm: "Generisches Formular",
            formOptionJsonEditor: "JSON-Editor",
            multipleProcessing: "Mehrfachbearbeitung",
            deactivated: "Deaktiviert",
            availableViews: "Verfügbare Views",
            genericForm: "Generisches Formular",
            viewTypeEditView: "Bearbeitungsansicht",
            buttonEdit: "Bearbeiten",
            buttonDelete: "Löschen",
            jsonEditor: "JSON-Editor",
            buttonConfirm: "Ja, entfernen",
            removeViewConfirmation: "View {{view_name}} {{view_id}} wirklich entfernen?",
            saveForEffectiveChange: "Die Änderung wird erst wirksam, wenn die Entität gespeichert wird.",
            buttonNewView: "Neue View",
            createView: "View anlegen",
            viewId: "View-ID",
            name: "Name",
            type: "Typ",
            buttonCancel: "Abbrechen",
            buttonAdd: "Hinzufügen",
            copyDocuments: "Dokumente kopieren",
            copyDocumentsHint: "Ist diese Funktion aktiv, wird neben dem Bearbeiten-Button in der Tabelle zusätzlich ein Kopieren-Button angezeigt, mit dem ein neues Dokument als Kopie des Gewählten erzeugt werden kann.",
            activateCloneButton: "Klonbutton aktivieren",
            advancedSettings: "Erweiterte Einstellungen",
            predefinedValuesForCopy: "Vordefinierte Werte für Kopie (JSON-Objekt)",
            viewNotFound: "View nicht gefunden",
            editView: "View {{view_name}} ({{view_id}}) bearbeiten",
            backToViewList: "Zurück zur View-Liste",
            componentList: "Komponenten-Liste",
            preview: "Vorschau",
            exampleData: "Beispiel-Daten",
        },
        qualityGates: {
            description: "Quality Gates aktualisieren alle Dokumente, die von der dazugehörigen Query gefunden werden.<br/>" +
              "So kann beispielsweise eine Query formuliert werden, welche alle Dokumente findet, bei denen ein bestimmtes Feld leer ist.<br/>" +
              "Für all diese Dokumente wird dann ein Update mit den Werten aus dem Update-Dokument durchgeführt.<br/><br/>" +
              "Die Query muss eine MongoDB Query sein, das Update-Dokument ein JSON-Objekt, welches die zu aktualisierenden Felder enthält.<br/>" +
              "Allen Feldnamen muss ein \"data.\" vorangestellt werden, wenn Felder aus dem Datenmodell angesprochen werden sollen.<br /><br />" +
              "Bitte beachten Sie, dass Quality Gates derzeit standardmäßig nicht den Update-Zeitstempel sowie die Änderungshistorie aktualisieren.",
            executionDescription1: "Die Quality Gates lassen sich automatisch in einem bestimmten Intervall ausführen. Dazu muss ein Ausführungsintervall gewählt werden.<br/>" +
              "Beachten Sie, dass der Ausführungszeitpunkt abhängig von der Datenmenge und Konfiguration der Quality Gates ist und demnach nicht immer exakt eingehalten werden kann.<br/>",
            executionDescription2: "Zudem kann sich die Ausführung der Quality Gates für diesen Entitätstypen über den API-Endpunkt ",
            executionDescription2_1: " angestoßen werden.",
            dropdownLabel: "Ausführungsintervall",
            updateDocument: "Update-Dokument",
            insertExample: "Beispiel einfügen",
            buttonTest: "Testen",
            buttonTestPopup: "Die Entität muss erst gespeichert werden, damit Quality Gates getestet werden können.",
            intervalOptions: {
                "neverExecute": "Nie ausführen",
                "30Seconds": "30 Sekunden",
                "60Seconds": "60 Sekunden",
                "300Seconds": "5 Minuten",
                "600Seconds": "10 Minuten"
            },
            modalTest: {
                loading: "Lade...",
                result: "Ergebnis",
                description: "Bei der Test-Ausführung wird geprüft, ob sich die Query und Update-Strings als MongoDB-Objekt einlesen lassen.",
                description1: "Anschließend wird mit dem Query-Objekt ein .count() in der Collection der Entität ausgeführt.",
                table: {
                    headerCellDuration: "Dauer (count)",
                    headerCellDocuments: "Dokumente",
                },
                hint: "Bei der echten Ausführung der Quality Gates ist aufgrund der Updates eine höhere Ausführungsdauer zu erwarten.",
                hint1: "Anhand dieser Werte kann jedoch möglicherweise eine Optimierung durch Indizes erprobt werden.",
                errorDuringExecution: "Fehler bei der Ausführung"
            }
        },
        translationTable: {
            header: "Übersetzungen",
            description: "Hier können Übersetzungen in verschiedenen Sprachen für diese Entität angelegt werden.",
            languageSelectionPlaceholder: "Sprachen...",
            table: {
                properties: "Eigenschaften",
                entityName: "Name der Entität",
                bucketName: "Name des Buckets",
                iconElaboration: {
                    archive: "Entität",
                    bookmark: "Referenz",
                    edit: "Feld",
                    book: "Definition"
                }
            }
        },
        json: {
            description: "JSON-Darstellung der vollständigen Entitätskonfiguration. Kann genutzt werden, um die Konfiguration in eine andere Umgebung zu übertragen.",
        }
    }
};

export default {
    en, de
};
