import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from './translations/translations';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: translations,

    whitelist: ['de', 'en'],
    fallbackLng: 'en',

    keySeparator: ".",
    nsSeparator: ":",
    debug: true,

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'cas.rdh.language',
      caches: ['localStorage']
    },

    interpolation: {
      escapeValue: false // not needed with react
    }
  });


export default i18n;
