const en = {
  // translations copied from CAS MON
  editPage: {
    editHeader: 'Edit Bucket {{bucket_name}}',
    createHeader: 'Create Bucket {{bucket_name}}',
    backButton: 'Back',
    saveButton: 'Save',
    handlerErrorTitle: 'error while saving',
    handlerSuccessTitle: 'Changes saved!',
    handlerSuccessText: 'Bucket has been saved.',
    errorMsg: 'No response from the server.',
    error_api_name_already_exists_msg: 'Bucket with this API name already exists!',
    error_api_name_not_specified: 'No API name was specified!',
    alert: "Leave page without saving changes?",
    menuTab: {
      general: "General",
      storageDriverSettings: "Storage driver",
      attributes: "Attributes",
      cardData: "Card layout",
      views: "Edit view",
      filter: "Filter",
      json: "JSON",
    }
  },
  listPage: {
    bucketsParagraph: 'Manage the buckets of the RDH file storage service. For each bucket it can be determined if the files should be stored in the RDH database or with an external service.',
    storageDriverDescription: "Storage driver: {{storageDriver}}",
    newButtonContent: 'New',
    editButton: 'Edit'
  },
  generalTab: {
    guiNameLabel: "GUI name",
    guiNameHelpText: "User friendly name which will be displayed in the user interface.",
    apiNameLabel: "API name",
    apiNameHelpText: "The API name is used to identify the bucket in the system and in the API.",
    iconLabel: "Icon",
    chooseIconButton: "Choose icon",
    iconHelpText: "Icon which will be displayed in the user interface.",
  },
  storageDriverSettingsTab: {
    storageDriverLabel: "Storage driver",
    storageDriverHelpText: "Use the \"rdh\"-storage driver if you want to store files in the RDH database." +
        " If you have your own server for storing files, you can configure it by selecting \"simple-http-service\".",
    storeUrlLabel: "Upload URL",
    storeUrlHelpText: "URL of the HTTP service for uploading files via HTTP post.",
    multipartNameLabel: "Multipart name",
    multipartNameHelpText: "Name of the multipart attribute in which the file is transferred.",
    storeResponseTypeLabel: "Response type",
    storeResponseTypeHelpText: "Specify whether the server returns the ID of the stored file directly as a plain string or as JSON.",
    storeResponseJsonPathLabel: "JSON path - file ID",
    storeResponseJsonPathHelpText: "Specify where in the response the ID of the uploaded file is located using a JSON Path. Example: $.id",
    retrieveUrlLabel: "Download URL",
    retrieveUrlHelpText: "URL of the HTTP service to download a file. Use the placeholder {{storedFileId}} to access the file ID returned by your server during upload.",
  },
  attributeTab: {
    schemeHeader: "Scheme",
    schemeDescription: "Optionally specify a JSON schema for file attributes here. Please use JSON schema draft v4 or v6",
    formatButton: "Format",
    errorFieldNotFound: "Field {{property_path}} not found in schema",
    errorInvalidSchemaDefinition: "Invalid schema: Definition object should not contain a definition with the internal name of the entity",
    errorSchemaMainLayer: "Invalid schema: Must describe an object/document on the root level (type = object)",
    errorInvalidSchema: "Invalid schema: ",
    errorInvalidJson: "Invalid JSON",
  },
  cardDataTab: {
    attributeLabel: "Attribute",
    labelLabel: "Label",
    cardDataHelpText: "Specify the attribute with \"data.\" in front. The label indicates which attribute it is.",
    table: {
      attributeHeaderCell: "Attribute",
      labelHeaderCell: "Label",
    },
    livePreview: {
      header: "Live-Preview",
      exampleFileNameLabel: "Example_File_Name.jpg",
      size: "<strong>Size: </strong>"
    }
  },
  filterTab: {
    searchFilterHeader: "Search filter",
    attributeLabel: "Attribute",
    labelLabel: "Label",
    searchFilterHelpText: "Specify the attributes to be used for searching. Custom attributes are specified preceded by \"data.\".",
    table: {
      attributeHeaderCell: "Attribute",
      labelHeaderCell: "Label"
    },
    sortFilterHeader: "Sorting filter",
    sortFilterHelpText: "Specify the attributes to be used for sorting. Custom attributes are specified preceded by \"data.\".",
  },
  saving: {
    successHeader: 'Changes saved',
    successText: 'Entity configuration saved.',
    error: {
      header: 'Error saving entity configuration',
      apiNameMissing: 'API Name is mandatory.',
      guiNameMissing: 'GUI Name is mandatory.',
      storageDriverMissing: 'Storage driver is mandatory.',
      storeUrlMissing: 'Store URL is mandatory.',
      responseTypeMissing: 'Response type is mandatory.',
      storeResponseJsonPathMissing: 'JSON-Path of response is mandatory.',
      retrieveUrlMissing: 'Retrieve URL is mandatory.',
      schemaNotObject: 'The provided schema does not describe a JSON object',
      schemaInvalidJson: 'The provided schema cannot be parsed',
      noResponse: 'No response from server'
    }
  },
};

const de = {
  editPage: {
    editHeader: 'Bucket {{bucket_name}} bearbeiten',
    createHeader: 'Bucket {{bucket_name}} erstellen',
    backButton: 'Zurück',
    saveButton: 'Speichern',
    handlerErrorTitle: 'Fehler beim Speichern',
    handlerSuccessTitle: 'Änderungen gespeichert!',
    handlerSuccessText: 'Bucket wurde gespeichert',
    errorMsg: 'Keine Antwort vom Server.',
    error_api_name_already_exists_msg: 'Bucket mit diesem API Namen existiert bereits!',
    error_api_name_not_specified: 'Es wurde kein API Name angegeben!',
    alert: "Seite verlassen ohne Änderungen zu speichern?",
    menuTab: {
      general: "Allgemein",
      storageDriverSettings: "Storage Driver",
      attributes: "Attribute",
      cardData: "Kartenlayout",
      views: "Bearbeitungsansicht",
      filter: "Filter",
      json: "JSON",
    }
  },
  listPage: {
    bucketsParagraph: 'Verwalten Sie die Buckets des RDH Dateispeicherservices. Für jeden Bucket kann festgelegt werden, ob die Dateien in der RDH Datenbank oder bei einem externen Dienst abgelegt werden sollen.',
    storageDriverDescription: "Storage Driver: {{storageDriver}}",
    newButtonContent: 'Neu',
    editButton: 'Bearbeiten'
  },
  generalTab: {
    guiNameLabel: "GUI Name",
    guiNameHelpText: "Benutzerfreundlicher Name, welcher in der Oberfläche angezeigt wird.",
    apiNameLabel: "API Name",
    apiNameHelpText: "Der API Name wird zur Identifizierung des Buckets im System und in der API verwendet.",
    iconLabel: "Icon",
    chooseIconButton: "Icon wählen",
    iconHelpText: "Symbol, welches in der Oberfläche angezeigt wird.",
  },
  storageDriverSettingsTab: {
    storageDriverLabel: "Storage Driver",
    storageDriverHelpText: "Verwenden Sie den \"rdh\"-storage driver, wenn Sie Dateien in der RDH Datenbank speichern möchten. " +
      "Sollten Sie einen eigenen Server zur Speicherung von Dateien besitzen, können Sie diesen konfigurieren, indem Sie \"simple-http-service\" wählen.",
    storeUrlLabel: "Upload URL",
    storeUrlHelpText: "URL des HTTP-Services zum Hochladen der Dateien per HTTP POST.",
    multipartNameLabel: "Multipart Name",
    multipartNameHelpText: "Name des Multipart Attributs, in welchem die Datei übertragen wird.",
    storeResponseTypeLabel: "Response Typ",
    storeResponseTypeHelpText: "Geben Sie an, ob der Server die ID der gespeicherten Datei direkt als String oder als JSON zurückgibt.",
    storeResponseJsonPathLabel: "JSON-Path - Datei-ID",
    storeResponseJsonPathHelpText: "Geben Sie mit einem JSON Path an, wo in der Response sich die ID der hochgeladenen Datei befindet. Beispiel: $.id ",
    retrieveUrlLabel: "Download URL",
    retrieveUrlHelpText: "URL des HTTP-Services zum Herunterladen einer Datei. Verwenden Sie den Platzhalter {{storedFileId}}, um auf die Datei-ID zuzugreifen, die Ihr Server beim Upload zurückgegeben hat.",
  },
  attributeTab: {
    schemeHeader: "Schema",
    schemeDescription: "Optional kann hier ein JSON Schema für die Dateiattributtierung angegeben werden. Bitte JSON Schema draft v4 oder v6 verwenden.",
    formatButton: "Formatieren",
    errorSchemaDefinition: "Ungültiges Schema: Definitions-Objekt sollte keine Definition mit dem internen Namen der Entität enthalten",
    errorSchemaMainLayer: "Ungültiges Schema: Muss auf der Hauptebene ein Objekt/Dokument beschreiben (type = object)",
    errorInvalidSchema: "Ungültiges Schema: ",
    errorInvalidJson: "Ungültiges JSON",
  },
  cardDataTab: {
    attributeLabel: "Attribute",
    labelLabel: "Label",
    cardDataHelpText: "Geben Sie das Attribut mit voranstehendem \"data.\" an. Das Label gibt an, um welches Attribut es sich handelt.",
    table: {
      attributeHeaderCell: "Attribute",
      labelHeaderCell: "Label",
    },
    livePreview: {
      header: "Live-Vorschau",
      exampleFileNameLabel: "Beispiel_Datei_Name.jpg",
      size: "<strong>Größe: </strong>",
    }
  },
  filterTab: {
    searchFilterHeader: "Suchfilter",
    attributeLabel: "Attribut",
    labelLabel: "Label",
    searchFilterHelpText: "Geben Sie die Attribute an, die für die Suche verwendet werden können. Eigene Attribute werden mit vorangestelltem \"data.\" angegeben.",
    table: {
      attributeHeaderCell: "Attribute",
      labelHeaderCell: "Label"
    },
    sortFilterHeader: "Sortierfilter",
    sortFilterHelpText: "Geben Sie an, nach welchen Attributen sortiert werden darf. Eigene Attribute werden mit voranstehendem \"data.\" angegeben.",
  },
  saving: {
    successHeader: 'Änderungen gespeichert',
    successText: 'Bucket wurde gespeichert.',
    error: {
      header: 'Fehler beim Speichern',
      apiNameMissing: 'API Name muss ausgefüllt werden.',
      guiNameMissing: 'GUI Name muss ausgefüllt werden.',
      storageDriverMissing: 'Storage driver muss ausgefüllt werden.',
      storeUrlMissing: 'Store URL muss ausgefüllt werden.',
      responseTypeMissing: 'Typ der Antwort muss ausgefüllt werden.',
      storeResponseJsonPathMissing: 'JSON-Path der Antwort muss ausgefüllt werden.',
      retrieveUrlMissing: 'URL zum Herunterladen der Datei muss ausgefüllt werden.',
      schemaNotObject: 'Das angegebene Schema beschreibt kein JSON Objekt',
      schemaInvalidJson: 'Das angegebene Schema ist kein gültiges JSON Dokument',
      noResponse: 'Keine Antwort vom Server.'
    }
  },
};

export default {
  en,de
};
