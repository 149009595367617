// import authService from "./AuthService";

export function userHasFeaturePermission(feature: string, ...parameters: any) {
    return true;
/*
    let featurePermissions = authService.principal.featurePermission || [];

    switch (feature) {
        case "view":

            for (let i = 0; i < featurePermissions.length; i++) {
                let featurePermission = featurePermissions[i];

                if (featurePermission.feature !== feature)
                    break;

                let entity : string = parameters[0];
                let viewName: string = parameters[1];

                if (featurePermission.permissions?.deny
                    && featurePermission.parameters?.entity === entity
                    && featurePermission.parameters?.views?.contains(viewName)
                ) {
                    return false;
                }
            }

            break;
        default:

            for (let i = 0; i < featurePermissions.length; i++) {
                let featurePermission = featurePermissions[i];

                if (featurePermission.feature === feature && featurePermission.permissions?.deny) {
                    return false;
                }
            }

            break;
    }
    return true;*/
}