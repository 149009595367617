import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Icon, Menu, MenuItemProps, MenuProps, Sidebar, SidebarProps} from 'semantic-ui-react';
import './MenuSidebar.css';
import {NavLink} from 'react-router-dom';
import AppRoute from '../AppRoute';
import {AuthPrincipal} from '../../Core/AuthService';
import InfoService from "../../Core/InfoService";
import {withTranslation, WithTranslation} from "react-i18next";

import logo from '../../../logo.png';
import {AppInfoContext} from "../AppInfoContext";
import {capitalize} from 'lodash';

interface MenuSidebarProps extends WithTranslation {
    open: boolean;
    onNavigate: Function;
    onLogout: Function;
    currentUser: AuthPrincipal;
    routes: AppRoute[];
    currentPath: string;
}

interface MenuSidebarState {
    environmentName?: string,
    logo?: string
}

class MenuSidebar extends React.Component<MenuSidebarProps, MenuSidebarState> {
    constructor(props: MenuSidebarProps) {
        super(props);
        this.navClickHandler = this.navClickHandler.bind(this);
        this.logoutClickHandler = this.logoutClickHandler.bind(this);
    }

    componentDidMount() {
        InfoService.getEnvironmentInfo().then((data: any) => {
            this.setState({
                environmentName: data.name,
                logo: data.logo
            });
        }).catch(() => {
            // no custom environment settings - is fine
        });
    }

    navClickHandler(ev: SyntheticEvent<HTMLAnchorElement>, data: MenuItemProps): void {
        this.props.onNavigate();
    }

    logoutClickHandler(ev: SyntheticEvent<HTMLAnchorElement>, data: MenuItemProps): void {
        this.props.onLogout();
    }

    reloadMenu() {
        this.forceUpdate();
    }

    reloadEnvironmentInfo(){
        InfoService.getEnvironmentInfo(true).then((data: any) => {
            this.setState({
                environmentName: data.name,
                logo: data.logo
            });
        }).catch(() => {
            // no custom environment settings - is fine
        });
    }

    render() {
        const {t} = this.props;

        const menuProps: MenuProps = {
            vertical: true,
            color: 'red'
        };

        const sidebarProps: {} | SidebarProps = {
            as: Menu,
            direction: 'left',
            width: 'wide',
            animation: 'push',
            ...menuProps
        };

        const menuItemProps: {} | MenuItemProps = {
            link: true,
            as: NavLink
        };

        return (
            <Sidebar className="menu-sidebar" {...sidebarProps} visible={this.props.open}>
                <Menu.Item className="menu-item-logo" header={true}>
                    {
                        this.context && this.context.logo ? (
                            <>
                                <img src={this.context.logo} alt="Logo" className="App-logo" />
                            </>) : (<img src={logo} className="App-logo" alt="Logo"/>)
                    }

                    {
                        this.context && this.context.name && this.context.name !== "" ? (
                            <>
                                <p className={"logo-caption"}> {this.context.name} </p>
                            </>) : <></>
                    }

                </Menu.Item>

                {this.props.routes
                    .filter(route => !route.hideInNav)
                    .filter(route => !(route.disabled && route.disabled()))
                    .map((route) =>
                    (!route.requiredRole || this.props.currentUser.roles.indexOf(route.requiredRole) !== -1) && [
                        <Menu.Item {...menuItemProps} to={route.path} key={route.path} onClick={this.navClickHandler}>
                            <Icon name={route.icon}/>
                            {t(route.title)}
                        </Menu.Item>,
                        ...(route.subRoutes && this.props.currentPath.startsWith(route.path)
                            ? [(<Menu.Menu key={route.path + '__submenu'} className="sub-menu">
                                {route.subRoutes
                                    .filter(subRoute => !(subRoute.disabled && subRoute.disabled()))
                                    // Sort by title
                                    .sort((sr1, sr2) => sr1.title && sr2.title && capitalize(sr1.title) > capitalize(sr2.title) ? 1 : 0)
                                    .map((subRoute) =>
                                    (!subRoute.requiredRole || this.props.currentUser.roles.indexOf(subRoute.requiredRole) !== -1)
                                    && (<Menu.Item {...menuItemProps} to={subRoute.path} key={subRoute.path}
                                                   title={!subRoute.noTranslate ? t(subRoute.title || '') : subRoute.title}
                                                   onClick={this.navClickHandler}>
                                        <Icon name={subRoute.icon}/>
                                        <div className="menu-item-title">{!subRoute.noTranslate ? t(subRoute.title || '') : subRoute.title}</div>
                                    </Menu.Item>))}
                            </Menu.Menu>)]
                            : [])
                    ]
                )}

            </Sidebar>
        );
    }
}

MenuSidebar.contextType = AppInfoContext;

export default withTranslation()(MenuSidebar);
