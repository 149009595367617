import AppRoute from './AppRoute';
import {lazy} from 'react';
import {userHasFeaturePermission} from "../Core/util";


const routes: AppRoute[] = [
    {path: '/data', component: lazy(() => import('../DataQuality/containers/DataQualityContainer')), title: 'navigation:route.data', icon: 'table'},

    {path: '/files', component: lazy(() => import('../Files/FileManagerRootPage')), title: 'navigation:route.files', icon: 'file alternate', requiredRole: 'ROLE_ACCESS_FILES'},
    {path: '/user', component: lazy(() => import('../UserSettings/UserProfilePage')), title: 'Benutzer', icon: 'user', hideInNav: true},

    {path: '/settings/entities', component: lazy(() => import('../Settings/EntityTypes/List/EntitiesContainer')), title: 'Entitäten', icon: 'database', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings-entity")},
    {path: '/settings/access-control', component: lazy(() => import('../Settings/UserManagement/AccessControlRootPage')), title: 'Zugriffskontrolle', icon: 'privacy', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings-access-control")},
    {path: '/settings/buckets', component: lazy(() => import('../Settings/BucketSettings/BucketSettingsRootPage')), title: 'Buckets', icon: 'file', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings-buckets")},
    {path: '/settings/environment', component: lazy(() => import('../Settings/SystemSettings/EnvironmentSettingsPage')), title: 'Umgebung', icon: 'server', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings-environment")},
    {path: '/settings/mail', component: lazy(() => import('../Settings/Mail/MailSettingsRootPage')), title: 'E-Mail', icon: 'mail', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings-mail")},
    {path: '/settings', component: lazy(() => import('../Settings/SystemSettings/SystemSettingsRootPage')), title: 'navigation:route.settings.main', icon: 'settings', requiredRole: 'ROLE_ACTUATOR', disabled: ()=>!userHasFeaturePermission("settings"),
    subRoutes: [
        {path: '/settings/access-control', title: 'navigation:route.settings.accessControl', icon: 'privacy', disabled: ()=>!userHasFeaturePermission("settings-access-control")},
        {path: '/settings/entities', title: 'navigation:route.settings.entityTypes', icon: 'database', disabled: ()=>!userHasFeaturePermission("settings-entity")},
        {path: '/settings/buckets', title: 'navigation:route.settings.buckets', icon: 'file', disabled: ()=>!userHasFeaturePermission("settings-buckets")},
        {path: '/settings/environment', title: 'navigation:route.settings.environment', icon: 'server', disabled: ()=>!userHasFeaturePermission("settings-environment")},
        {path: '/settings/mail', title: 'navigation:route.settings.mail', icon: 'mail', disabled: ()=>!userHasFeaturePermission("settings-mail")}
    ]},

    {path: '/api-explorer', component: lazy(() => import('../ApiExplorer/ApiExplorer')), title: 'API Explorer', icon: 'server', hideInNav: true, disabled: ()=>!userHasFeaturePermission("settings")},
];

export default routes;
