import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
// import 'semantic-ui-css/semantic.min.css';
//import 'fomantic-ui-css/semantic.min.css';
import './stylesheets/Lato/latofonts.css'; 
import './stylesheets/semantic.min.css';
import './index.css';
import {HashRouter} from 'react-router-dom';
import AppContainer from './modules/App/AppContainer/AppContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './i18n';
import {AppInfoContextProvider} from "./modules/App/AppInfoContext";

ReactDOM.render(
    <HashRouter>
      <AppInfoContextProvider>
        <AppContainer/>
      </AppInfoContextProvider>
      <ToastContainer />
    </HashRouter>,
    document.getElementById('root') as HTMLElement
);
// registerServiceWorker();
