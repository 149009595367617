import * as React from 'react';
import './AppContainer.css';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router';
import LoginContainer from '../../Auth/LoginContainer/LoginContainer';
import MainLayoutContainer from '../MainLayoutContainer/MainLayoutContainer';
import {default as authService} from '../../Core/AuthService';
import {AppInfoContext} from "../AppInfoContext";

type AppContainerState = {
    loading: boolean
};

interface AppContainerProps extends RouteComponentProps<{},{}> {}

class AppContainer extends React.Component<AppContainerProps, AppContainerState> {
    constructor(props: AppContainerProps) {
        super(props);
        this.state = {loading: true};
    }

    async componentDidMount () {
        try {
            await authService.checkIfLoggedIn();
        } catch(e) {
            // user is not logged in - this is okay since component then redirects to login
        } finally {
            this.setState({loading: false});
        }

        this.updateTitle();
    }

    componentDidUpdate() {
        this.updateTitle();
    }

    updateTitle() {
        const appInfo = this.context;

        let environmentName: string = typeof appInfo.name === 'string' ? appInfo.name : '';

        // when environment name already starts with "RDH " or "CAS RDH ", remove it
        environmentName = environmentName.replace(/^RDH /, '')
          .replace(/^CAS RDH /, '')
          .trim();

        document.title = 'CAS RDH' + (environmentName.length > 0 ? ' | ' + environmentName : '');
    }

    render() {
        return this.state.loading ? null : (
            <Switch>
                <Route exact={false} path="/login" component={LoginContainer}/>
                {authService.loggedIn && <Route component={MainLayoutContainer}/>}
                {!authService.loggedIn && <Redirect path="/"
                                                    to={this.props.location.pathname === '/'
                                                        ? '/login'
                                                        : '/login?redirectTo=' + encodeURIComponent(this.props.location.pathname)} />}
            </Switch >
        );
    }
}

AppContainer.contextType = AppInfoContext;

export default withRouter(AppContainer);
