// Translations copied from CASMON

const en = {
  size_error: 'The selected logo may not exceed 2 MiB (in size).',
  format_error: "The selected logo must be uploaded in one of the following formats: .jpg / .png",
  name_or_base_url_missing_error: "Environment name and URL must be specified.",
  unknown_error: "An unknown error has occurred.",
  header: 'Environment settings',
  system_name_label: 'Environment name',
  system_name_help_text: 'The name of the environment is displayed in the navigation and on the login screen',
  url_help_text: 'Absolute URL to RDH - required for API documentation, emails and integration with other systems',
  logo: "Chosen logo",
  reset_logo: 'Delete Logo',
  saveButton: 'Save'
};

const de = {
  size_error: 'Das ausgewählte Logo darf eine Größe von 2 MiB nicht überschreiten.',
  format_error: "Das ausgewählte Logo muss in einem der folgenden Formate hochgeladen werden: .jpg/.png",
  name_or_base_url_missing_error: "Umgebungsname und URL müssen angegeben sein.",
  unknown_error: "Ein unbekannter Fehler ist aufgetreten.",
  header: 'Umgebungseinstellungen',
  system_name_label: 'Umgebungsname',
  system_name_help_text: 'Name der Umgebung. Wird in der Navigation und auf dem Login-Bildschirm angezeigt',
  url_help_text: 'Absolute URL zum RDH - wird für API Dokumentationen, E-Mails und Anbindung an andere Systeme benötigt',
  logo: "Gewähltes Logo",
  reset_logo: 'Logo löschen',
  saveButton: 'Speichern'
};

export default {
  en,de
};
