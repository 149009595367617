const en = {
  header: 'Quality Gate Log',
  infoText: "This page shows the status of the last Quality Gate executions for this entity.<br/>" +
    "Log entries for Quality Gates are kept for 30 days.",
  table: {
    headerCell: {
      time: "Time",
      duration: "Duration",
    },
    hasErrorText: "Contains Errors",
    emptyText: "No log entries found.",
    buttonRefresh: "Refresh"
  },
  modal: {
    details: {
      table: {
        headerCell: {
          qualityGateId: "Quality Gate id",
          name: "Name",
          duration: "Duration",
          documents: "Documents"
        },
      },
      button: {
        showError: "Show error",
        close: "Close"
      }
    },
    error: {
      errorHeader: "Error",
      button: {
        close: "Close"
      }
    }
  }
};

const de = {
  header: 'Quality Gate Log',
  infoText: "Hier erhalten Sie eine tabellarische Übersicht der letzten Quality Gate Ausführungen für diese Entität.<br/>" +
    "Derzeit werden Logeinträge von Quality Gates für 30 Tage vorgehalten.",
  table: {
    headerCell: {
      time: "Zeitpunkt",
      duration: "Laufzeit",
    },
    hasErrorText: "Enthält Fehler",
    emptyText: "Keine Log-Einträge vorhanden.",
    buttonRefresh: "Aktualisieren"
  },
  modal: {
    details: {
      table: {
        headerCell: {
          qualityGateId: "Quality Gate Id",
          name: "Name",
          duration: "Laufzeit",
          documents: "Dokumente"
        }
      },
      button: {
        showError: "Fehler anzeigen",
        close: "Schließen"
      }
    },
    error: {
      errorHeader: "Fehler",
      button: {
        close: "Schließen"
      }
    }
  }
};

export default {
  en, de
};
