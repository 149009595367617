const en = {
    header: 'User profile',
    subText: '',
    buttonSave: 'Save',
    userName: "Name",
    userEmail: "Email",

    error: {
        currentPasswordIsEmpty: 'Current password is empty',
        passwordsDoNotMatch: 'Passwords do not match',
        passwordTooShort: 'The new password must contain at least 8 characters',
        currentPasswordInvalid: 'The provided current password is not correct',
        unknown: 'There was an error saving your changes'
    },
    passwordModal: {
        changePassword: 'Change password',
        currentPassword: 'Current password',
        password: 'New password',
        newPasswordConfirmation: 'Confirm new password',
        buttonCancel: 'Cancel'
    },
    successMessage: 'Saved Changes!'
};

const de = {
    header: 'Benutzerkonto',
    subText: '',
    buttonSave: 'Speichern',
    userName: "Name",
    userEmail: "E-Mail",

    error: {
        currentPasswordIsEmpty: 'Das derzeitige Passwort ist leer',
        passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
        passwordTooShort: 'Das neue Passwort muss mindestens 8 Zeichen beinhalten',
        currentPasswordInvalid: 'Das angegebene aktuelle Passwort ist nicht korrekt.',
        unknown: 'Es ist ein Fehler beim Speichern der Änderungen aufgetreten. Bitte überprüfen Sie Ihre Eingabe.'
    },
    passwordModal: {
        changePassword: 'Passwort ändern',
        currentPassword: 'Derzeitiges Passwort',
        password: 'Neues Passwort',
        newPasswordConfirmation: 'Neues Passwort bestätigen',
        buttonCancel: 'Abbrechen'
    },
    successMessage: 'Änderungen gespeichert!'
};

export default {
    en, de
};
