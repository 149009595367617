import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Form, Header, Loader, Message} from "semantic-ui-react";
import RestClient from "../../../lib/RestClient";
import {NavLink, RouteComponentProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const SetNewPasswordContainer = (props: {} & RouteComponentProps<{
  id: string;
  token: string;
}>) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tokenIdInvalid, setTokenIdInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    RestClient.post('auth/password-recovery/precheck', {tokenId: props.match.params.id})
      .then(() => {
        setLoading(false);
        setTokenIdInvalid(false);
      }).catch(() => {
        setLoading(false);
        setTokenIdInvalid(true);
    })
  }, [props.match.params.id]);

  const submit = useCallback(() => {
    setSubmitting(true);
    setError(false);
    setSuccess(false);
    RestClient.post('auth/password-recovery/reset', {tokenId: props.match.params.id, token: props.match.params.token, newPassword: password})
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
      }).catch(() => {
        setError(true); // todo differentiate between too weak password and id/token not valid
        setSubmitting(false);
      });
  }, [props.match.params.id, props.match.params.token, password]);

  if(loading) {
    return <Loader active={true} />;
  }
  if(tokenIdInvalid) {
    return (<Message negative>
      <Message.Header>{t('auth:password_recovery_set.expired_header')}</Message.Header>
      <p>{t('auth:password_recovery_set.expired_text')}</p>
    </Message>);
  }
  if(success) {
    return (<>
      <Message positive>
        <Message.Header>{t('auth:password_recovery_set.success_header')}</Message.Header>
        <p>{t('auth:password_recovery_set.success_text')}</p>
      </Message>
      <NavLink to={"/login"} className="ui button primary fluid">{t('auth:password_recovery_set.link_login')}</NavLink>
    </>)
  }
  return (<>
    <Header as="h4" >
      {t('auth:password_recovery_set.header')}
    </Header>
    <Form onSubmit={submit}>
      <Form.Input
        label={t('auth:password_recovery_set.form_password')}
        icon="key"
        name="new-password"
        type="password"
        required={true}
        value={password}
        autoComplete="new-password"
        disabled={submitting}
        onChange={(_, data) => setPassword(data.value)}
      />
      <Form.Input
        label={t('auth:password_recovery_set.form_password_confirm')}
        icon="key"
        name="confirm-new-password"
        type="password"
        required={true}
        value={passwordConfirmation}
        autoComplete="new-password"
        error={password !== passwordConfirmation && passwordConfirmation !== ''}
        disabled={submitting}
        onChange={(_, data) => setPasswordConfirmation(data.value)}
      />
      <Form.Button loading={submitting}
                   disabled={submitting || (password.length < 8 || password !== passwordConfirmation)}
                   type="submit"
                   fluid primary content={t('auth:password_recovery_set.form_submit')}/>

      {error && (
        <Message negative>
          <Message.Header>{t('auth:password_recovery.error_header')}</Message.Header>
          <p>{t('auth:password_recovery.error_text')}</p>
        </Message>
      )}
    </Form>
  </>);
};
