const en = {
  home: {
    title: 'Data',
    empty: {
      header: 'No data objects found',
      text: 'You currently do not have access to any data objects.'
    }
  },
  editForm: {
    errorParsingSchema: 'Could not load schema configuration.',
    errorLoadingDocument: 'Unable to load document.',
    saveError: {
      badRequest: 'Document is not valid or required fields are missing',
      noAuthentication: 'Your session is expired',
      missingPermissions: 'You are not allowed to perform the requested action',
      notFound: 'Document not found',
      alreadyExists: 'There already is a document with the same identifier',
      conflict: 'Conflict updating document due to another user updating it at the same time',
      default: 'Error while saving: ' ,
      unknown: 'Unknown error'
    },
    tabs: {
      viewForm: 'Form',
      viewRaw: 'JSON'
    },
    headerEdit: 'Edit document',
    headerClone: 'Copy document',
    headerCreate: 'Create document',
    buttonCancel: 'Cancel',
    buttonSave: 'Save',
  },
  conflictModal: {
    header: "Conflict when updating the document",
    text: "A conflict has occurred while updating the document because another user has made changes in the meantime.<br/>" +
      "Do you want to overwrite the current document to save your changes?",
    buttonCancel: "No",
    buttonConfirm: "Yes"
  },
  listView: {
    buttonNew: 'New',
    buttonDeselectAll: 'Deselect All',
    buttonUpdateSelection: 'Edit Selection ({{count}})',
    errorLoading: 'An error occured while loading the documents.',
    emptyText: 'No documents found.',
    buttonRefresh: 'Refresh',
    documentsFoundText: "documents found"
  },
  filterView: {
    header: 'Filter',
    buttonReset: 'Reset',
    buttonApply: 'Apply'
  },
  deleteModal: {
    header: 'Delete Document',
    text: 'Do you really want to delete this document?',
    buttonCancel: 'No',
    buttonConfirm: 'Yes'
  }
};

const de = {
  home: {
    title: 'Daten',
    empty: {
      header: 'Keine Datenobjekte gefunden',
      text: 'Sie haben derzeit keinen Zugriff auf Datenobjekte.'
    }
  },
  editForm: {
    errorParsingSchema: 'Konnte Schema der Entität nicht lesen.',
    errorLoadingDocument: 'Dokument konnte nicht geladen werden.',
    saveError: {
      badRequest: 'Dokument entspricht nicht Schema oder Pflichtfelder nicht gefüllt',
      noAuthentication: 'Fehlende Authentifizierung',
      missingPermissions: 'Unzureichende Berechtigungen',
      notFound: 'Die angeforderte Ressource wurde nicht gefunden',
      alreadyExists: 'Es existiert bereits ein Dokument mit diesem Schlüssel',
      conflict: 'Konflikt mit dem aktuellen Status des Dokuments',
      default: 'Fehler beim Speichern: ' ,
      unknown: 'Unbekannter Fehler'
    },
    tabs: {
      viewForm: 'Formular',
      viewRaw: 'JSON'
    },
    headerEdit: 'Dokument bearbeiten',
    headerClone: 'Dokument kopieren',
    headerCreate: 'Dokument erstellen',
    buttonCancel: 'Abbrechen',
    buttonSave: 'Speichern',
  },
  conflictModal: {
    header: "Konflikt beim Aktualisieren des Dokuments",
    text: "Beim Aktualisieren des Dokuments ist ein Konflikt aufgetreten, weil ein anderer Benutzer in der " +
      "Zwischenzeit Änderungen vorgenommen hat.<br/>" +
      "Möchten Sie das derzeitige Dokument überschreiben, um Ihre Änderungen zu speichern?",
    buttonCancel: "Nein",
    buttonConfirm: "Ja"
  },
  listView: {
    buttonNew: 'Neu',
    buttonDeselectAll: 'Auswahl aufheben',
    buttonUpdateSelection: 'Auswahl ({{count}}) bearbeiten',
    errorLoading: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.',
    emptyText: 'Keine Datensätze gefunden.',
    buttonRefresh: 'Aktualisieren',
    documentsFoundText: "Dokumente gefunden"
  },
  filterView: {
    header: 'Filter',
    buttonReset: 'Zurücksetzen',
    buttonApply: 'Übernehmen'
  },
  deleteModal: {
    header: 'Dokument löschen',
    text: 'Wollen Sie dieses Dokument wirklich löschen?',
    buttonCancel: 'Nein',
    buttonConfirm: 'Ja'
  }
};

export default {
  en,de
};
