// Themes, Modes and Extensions for Ace Editor
// Consolidated in a central place to avoid different behaviour depending on which ace editor instance was loaded first
// and which extensions/modes/themes were available at that time

import { config } from 'ace-builds';

/* eslint-disable import/no-webpack-loader-syntax */

config.setModuleUrl('ace/mode/json', require('file-loader?esModule=false!ace-builds/src-min-noconflict/mode-json.js'));
config.setModuleUrl('ace/mode/json_worker', require('file-loader?esModule=false!ace-builds/src-min-noconflict/worker-json.js'));
config.setModuleUrl('ace/mode/javascript', require('file-loader?esModule=false!ace-builds/src-min-noconflict/mode-javascript.js'));

config.setModuleUrl('ace/ext/searchbox', require('file-loader?esModule=false!ace-builds/src-min-noconflict/ext-searchbox.js'));

config.setModuleUrl('ace/theme/chrome', require('file-loader?esModule=false!ace-builds/src-min-noconflict/theme-chrome.js'));
