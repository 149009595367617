// Translations copied from CASMON

const en = {
  setting_editor: {
    save_change_error: 'Changes could not be saved',
    header: 'Outbound email',
    paragraph: 'Configure a SMTP connection to allow CAS RDH to send emails.',
    enabled_label: 'Active',
    from_name_label: 'Sender name',
    from_address_label: 'Sender email',
    none_text: 'None',
    unchanged_placeholder: '(unchanged)',
    use_authentication_label: 'Authentication',
    user_name_label: 'Username',
    password_label: 'Password',
    cancel_button: 'Cancel',
    test_connection_button: 'Test Connection',
    saveButton: 'Save'
  },
  setting_summary: {
    header: 'Outgoing email',
    paragraph: 'Configure a SMTP connection to allow CAS RDH to send emails.',
    edit_button: 'Edit',
    enabled_label: 'Active',
    from_name_label: 'Sender name',
    from_address_label: 'Sender email',
    none_text: 'Unencrypted',
    use_authentication_label: 'Authentication',
    user_name_label: 'Username'
  },
  send_test_mail: {
    unknown_error_msg: 'Unknown error',
    header: 'Send test email',
    receiver_label: 'Recipient',
    send_button: 'Send',
    success_msg: 'Mail sent successfully',
    success_msg_info: 'Check your mailbox to see if the email could be delivered.',
    errorMsg: 'Email could not be sent'
  },
  test_mail_connection: {
    unknown_error_msg: 'Unknown error',
    msg_header: 'Testing connection ...',
    msg_header_success: 'Connection successful',
    msg_header_error: 'Connection could not be established',
    modal_header: 'Test connection',
    close_button: 'Close'
  }
};

const de = {
  setting_editor: {
    save_change_error: 'Änderungen konnten nicht gespeichert werden',
    header: 'Ausgehende E-Mail',
    paragraph: 'Konfigurieren Sie eine SMTP-Verbindung, damit CAS RDH E-Mails versenden kann.',
    enabled_label: 'Aktiviert',
    from_name_label: 'Absender Name',
    from_address_label: 'Absender E-Mail',
    none_text: 'Keine',
    unchanged_placeholder: '(unverändert)',
    use_authentication_label: 'Authentifizierung',
    user_name_label: 'Benutzername',
    password_label: 'Passwort',
    cancel_button: 'Abbrechen',
    test_connection_button: 'Verbindung Testen',
    saveButton: 'Speichern'
  },
  setting_summary: {
    header: 'Ausgehende E-Mail',
    paragraph: 'Konfigurieren Sie eine SMTP-Verbindung, damit CAS RDH E-Mails versenden kann.',
    edit_button: 'Bearbeiten',
    enabled_label: 'Aktiviert',
    from_name_label: 'Absender Name',
    from_address_label: 'Absender E-Mail',
    none_text: 'Unverschlüsselt',
    use_authentication_label: 'Authentifizierung',
    user_name_label: 'Benutzername'
  },
  send_test_mail: {
    unknown_error_msg: 'Unbekannter Fehler',
    header: 'Mail-Versand testen',
    receiver_label: 'Empfänger',
    send_button: 'Senden',
    success_msg: 'Mail erfolgreich versandt',
    success_msg_info: 'Prüfen Sie Ihr Postfach, um zu sehen, ob die E-Mail zugestellt werden konnte.',
    errorMsg: 'E-Mail konnte nicht versendet werden'
  },
  test_mail_connection: {
    unknown_error_msg: 'Unbekannter Fehler',
    msg_header: 'Teste Verbindung...',
    msg_header_success: 'Verbindung erfolgreich',
    msg_header_error: 'Verbindung konnte nicht aufgebaut werden',
    modal_header: 'Verbindung testen',
    close_button: 'Schließen'
  }
};

export default {
  en,de
};