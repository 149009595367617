const en = {
  header: 'Document export of the entity "{{displayName}}"',
  notStarted: {
    fieldOption: "Already existing documents...",
    checkboxOptions: {
      replace: "replace",
      merge: "merge",
      dontChange: "don't change",
    },
  },
  error: {
    header: "An export is not possible!",
    tooLargeSize: "Currently the export of entities with an uncompressed document size of up to 150 MiB is supported. {{displayName}} exceeds with " +
      "{{entitySize}} the maximum allowed size.",
    noDocuments: "{{displayName}} doesn't contain documents."
  },
  exportNotStarted: {
    description: "{{displayName}} contains {{numberOfDocuments}} documents.",
    confirmation: "Do you want to start exporting all documents?",
    hint: "Please ensure a stable internet connection during the export and do not close the application!"
  },
  exporting: {
    header: "Export is in progress",
    hint: "Please ensure a stable internet connection during the export and do not close the application!"
  },
  finished: {
    header: "Export successfully completed",
  },
  cancel: {
    header: "Cancel export",
    confirmation: "Do you really want to cancel the export?",
  },

  buttonClose: "Close",
  buttonCancel: "Cancel",
  buttonNo: "No",
  buttonYes: "Yes",
  buttonStartExport: "Start export",
};

const de = {
  header: 'Dokumentenexport der Entität "{{displayName}}"',
  notStarted: {
    fieldOption: "Bereits existierende Dokumente...",
    checkboxOptions: {
      replace: "ersetzen",
      merge: "zusammenführen",
      dontChange: "nicht verändern",
    },
  },
  error: {
    header: "Ein Export ist nicht möglich!",
    tooLargeSize: "Derzeit wird der Export von Entitäten mit einer unkomprimierten Dokumentengröße von bis zu 150 MiB unterstützt. {{displayName}} überschreitet mit " +
      "{{entitySize}} die maximal zulässige Größe.",
    noDocuments: "{{displayName}} enthält keine Dokumente."
  },
  exportNotStarted: {
    description: "{{displayName}} enthält {{numberOfDocuments}} Dokumente.",
    confirmation: "Möchten Sie den Export aller Dokumente starten?",
    hint: "Bitte gewährleisten Sie während des Exports eine stabile Internetverbindung und schließen Sie die Applikation nicht!"
  },
  exporting: {
    header: "Export wird ausgeführt",
    hint: "Bitte gewährleisten Sie während des Exports eine stabile Internetverbindung und schließen Sie die Applikation nicht!"
  },
  finished: {
    header: "Export erfolgreich beendet",
  },
  cancel: {
    header: "Export abbrechen",
    confirmation: "Wollen Sie den Export wirklich abbrechen?",
  },

  buttonClose: "Schließen",
  buttonCancel: "Abbrechen",
  buttonNo: "Nein",
  buttonYes: "Ja",
  buttonStartExport: "Export starten",

};

export default {
  en, de
};
