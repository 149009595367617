/*
 * CAS-MDH
 * Proprietary Software Solution
 * CAS Concepts and Solutions AG
 * Copyright (C) 2020 CAS Concepts and Solutions AG
 * mailto: info [at] c-a-s [dot] de
 *
 * CAS-MDH is a proprietary software solution distributed and licensed
 * by CAS Concepts and Solutions AG
 * You may not modify or change this code without permission.
 */

import * as React from 'react';
import {Header, Form, Message, Icon} from 'semantic-ui-react';
import {useState, useCallback} from 'react';
import RestClient from "../../../lib/RestClient";
import {NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ResetPasswordContainer = (props: {}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = useCallback(() => {
    setSubmitting(true);
    setError(false);
    setSuccess(false);
    RestClient.post('auth/password-recovery', {email: email})
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
              }).catch(() => {
                setError(true);
                setSubmitting(false);
              });
  }, [email]);

  if(success) {
    return (<>
      <Header as="h4" >
        {t('auth:password_recovery.header')}
      </Header>

      <Message positive>
        <p>{t('auth:password_recovery.instructions_success')}</p>
      </Message>
      </>);
  }

   return (<>
     <Header as="h4" >
       {t('auth:password_recovery.header')}
     </Header>
     <p>{t('auth:password_recovery.instructions_start')}</p>
     <Form onSubmit={submit}>
       <Form.Input
           label={t('auth:password_recovery.form_email')}
           icon="mail"
           name="email"
           type="email"
           required={true}
           value={email}
           disabled={submitting}
           onChange={(_, data) => setEmail(data.value)}
       />
       <Form.Button loading={submitting} disabled={submitting} type="submit"  fluid primary content={t('auth:password_recovery.form_submit')}/>
       {!submitting && <NavLink className="" to="/login"><Icon name="arrow left" /> {t('auth:password_recovery.link_back')}</NavLink>}

       {error && (
       <Message negative>
         <Message.Header>{t('auth:password_recovery.error_header')}</Message.Header>
         <p>{t('auth:password_recovery.error_text')}</p>
       </Message>
       )}
     </Form>
   </>);
};

export default ResetPasswordContainer;