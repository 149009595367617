import {default as axios, AxiosInstance, AxiosPromise, AxiosResponse} from 'axios';

type Params = {[key: string]: any};

class RestClient {
    private client: AxiosInstance;
    private requests: number = 0;

    constructor () {
        this.client = axios.create({
            baseURL: './api'
        });
        this.client.defaults.xsrfCookieName = 'XSRF-TOKEN';
        this.client.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
        this.injectRequestCounter();
    }

    delete (path: string, params?: Params) {
        return this.call(path, {method: 'delete', params});
    }

    get (path: string, params?: Params, options?:{}) {
        return this.call(path, {method: 'get', params, ...options});
    }

    patch (path: string, data: {}, params?: Params, options?: {}) {
        return this.call(path, {method: 'patch', data, params, ...options});
    }

    post (path: string, data?: {}, params?: Params, options?:{}) {
        return this.call(path, {method: 'post', data, params, ...options});
    }

    put (path: string, data: {}, params?: Params, options?: {}) {
        return this.call(path, {method: 'put', data, params, ...options});
    }

    setHeader (key: string, value: any) {
        this.client.defaults.headers[key] = value;
    }

    requestsInProgress () {
        return this.requests > 0;
    }

    axiosInstance() {
        return this.client;
    }

    waitForRequests (MAX_TRIES: number = 10) {
        return new Promise<void>((resolve, reject) => {
            let tries = 0;
            let check = () => {
                //console.log('checking', this.requests);
                if (tries > MAX_TRIES) { reject(); return; }
                if (!this.requestsInProgress()) {
                    resolve();
                    return;
                }
                setTimeout(check, 100);
            };
            check();
        });
    }

    private call (path: string, options: {}) {
        return this.unwrapPromise(this.client.request({url: path, ...options}));
    }

    private unwrapPromise (promise: AxiosPromise): Promise<AxiosResponse> {
        return new Promise((resolve, reject) => promise.then(resolve, reject));
    }

    private injectRequestCounter () {
        let handleError = (error: Error) => {
            this.requests--;
            // console.log('DOWN.ERR', this.requests);
            return Promise.reject(error);
        };
        this.client.interceptors.request.use((request) => {
            this.requests++;
            // console.log('UP', this.requests);
            return request;
        }, handleError.bind(this));
        this.client.interceptors.response.use((response) => {
            this.requests--;
            // console.log('DOWN', this.requests);
            return response;
        }, handleError.bind(this));
    }
}

export default new RestClient();
