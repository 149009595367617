const en = {
  header: 'Document import of the entity "{{displayName}}".',
  notStarted: {
    fieldOption: "Already existing documents...",
    checkboxOptions: {
      replace: "replace",
      merge: "merge",
      dontChange: "don't change",
    },
    tooLargeSizeError: "Currently only JSON files with a maximum size of 150 MiB can be imported.",
    jsonError: "Document could not be parsed as JSON",
    fileHeader: "File",
    fileHint: "Drag 'n' drop a file here or click here to select files.",
    fileInfo: "{{fileName}} - {{fileSize}} bytes - {{amountOfDocuments}} documents",
  },
  fileUploaded: {
    description: "Do you want to start the import of all documents?",
    hint: "Please ensure a stable internet connection during the import and do not close the window!"
  },
  importStarted: {
    header: "Import is in progress",
    hint: "Please ensure a stable internet connection during the import and do not close the window!",
  },
  finished: {
    headerSuccessfully: "Import successfully completed",
    headerWithErrors: "Import completed with errors",
    headerWithWarnings: "Import completed with warnings"
  },
  statistics: {
    createSuccess: "{{createSuccessAmount}} new documents created",
    updateSuccess: "{{updateSuccessAmount}} documents updated",
    alreadyExistsAmount: "{{alreadyExistsAmount}} documents already exist",
    wrongSchemeAmount: "{{wrongSchemeAmount}} documents do not match the schema",
    missingPermissionsAmount: "{{missingPermissionsAmount}} missing permissions",
    notFoundAmount: "{{notFoundAmount}} documents not found",
  },
  cancel: {
    header: "Cancel import",
    hint: "Previously saved documents are retained.",
    confirmation: "Do you really want to cancel the import?",
  },

  buttonClose: "Close",
  buttonCancel: "Cancel",
  buttonBack: "Back",
  buttonContinue: "Continue",
  buttonNo: "No",
  buttonYes: "Yes",
  buttonStartImport: "Start import",

};

const de = {
  header: 'Dokumentenimport der Entität "{{displayName}}"',
  notStarted: {
    fieldOption: "Bereits existierende Dokumente...",
    checkboxOptions: {
      replace: "ersetzen",
      merge: "zusammenführen",
      dontChange: "nicht verändern",
    },
    tooLargeSizeError: "Derzeit können nur JSON-Dateien mit einer Dateigröße von maximal 150 MiB importiert werden.",
    jsonError: "Dokument konnte nicht als JSON geparsed werden",
    fileHeader: "Datei",
    fileHint: "Ziehen Sie eine Datei per Drag 'n' Drop hierher oder klicken Sie hier, um Dateien auszuwählen.",
    fileInfo: "{{fileName}} - {{fileSize}} Bytes - {{amountOfDocuments}} Dokumente",
  },
  fileUploaded: {
    description: "Möchten Sie den Import aller Dokumente starten?",
    hint: "Bitte gewährleisten Sie während des Imports eine stabile Internetverbindung und schließen Sie die Applikation nicht!"
  },
  importStarted: {
    header: "Import wird ausgeführt",
    hint: "Bitte gewährleisten Sie während des Imports eine stabile Internetverbindung und schließen Sie die Applikation nicht!",
  },
  importFinished: {
    header: "Import erfolgreich beendet",
  },
  statistics: {
    createSuccess: "{{createSuccessAmount}} neue Dokumente angelegt",
    updateSuccess: "{{updateSuccessAmount}} Dokumente aktualisiert",
    alreadyExistsAmount: "{{alreadyExistsAmount}} Dokumente existieren bereits",
    wrongSchemeAmount: "{{wrongSchemeAmount}} Dokumente passen nicht zum Schema",
    missingPermissionsAmount: "{{missingPermissionsAmount}} fehlende Berechtigungen",
    notFoundAmount: "{{notFoundAmount}} Dokumente nicht gefunden",
  },
  cancel: {
    header: "Import abbrechen",
    hint: "Bereits gespeicherte Dokumente bleiben bestehen.",
    confirmation: "Wollen Sie den Import wirklich abbrechen?",
  },

  buttonClose: "Schließen",
  buttonCancel: "Abbrechen",
  buttonBack: "Zurück",
  buttonContinue: "Fortfahren",
  buttonNo: "Nein",
  buttonYes: "Ja",
  buttonStartImport: "Import starten",

};

export default {
  en, de
};
