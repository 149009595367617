const en = {
  route: {
    data: 'Data',
    files: 'Files',
    settings: {
      main: 'Settings',
      accessControl: 'Identity & Access Management',
      entityTypes: 'Entity types',
      buckets: 'Buckets',
      environment: 'Environment',
      mail: 'Email'
    }
  }
};

const de = {
  route: {
    data: 'Daten',
    files: 'Dateien',
    settings: {
      main: 'Einstellungen',
      accessControl: 'Zugriffskontrolle',
      entityTypes: 'Entitätstypen',
      buckets: 'Buckets',
      environment: 'Umgebung',
      mail: 'E-Mail'
    }
  }
};

export default {
  en,de
};