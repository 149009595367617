const en = {
  listPage:{
    type: "Type",
    editFile: "Edit file",
    uploadFile: "Upload file",
    updateFile: "Update file",
    upload: "Upload",
    size: "Size",
    fileSize: "File size",
    cancel: "Cancel",
    files: "Files",
    fileName: "File name",
    creationDate: "Created at",
    updatedAt: "Updated at",
    searchResultsFor: "Search results for",
    saveFileForUpload: "Save file for upload",
    deleteFile: "Delete file",
    deleteFileWarningMessage: {
      phrase1: "Do you really want to delete the file \"{{fileName}}\"?",
      phrase2: "This process cannot be undone."
    },
    approveDeletionOfFile: "Yes, delete file"
  },
  rootPage: {},
  startPage: {
    fileManager: "File-Manager",
    fileCount: "File count",
    totalSize: "Total size",
    open: "Open"
  }
};

const de = {
  listPage:{
    type: "Typ",
    editFile: "Datei bearbeiten",
    uploadFile: "Datei hochladen",
    updateFile: "Datei aktualisieren",
    upload: "Hochladen",
    size: "Größe",
    fileSize: "Dateigröße",
    cancel: "Abbrechen",
    files: "Dateien",
    fileName: "Dateiname",
    creationDate: "Erstelldatum",
    updatedAt: "Änderungsdatum",
    searchResultsFor: "Sucherergebnisse für",
    saveFileForUpload: "Datei ablegen zum Hochladen",
    deleteFile: "Datei löschen",
    deleteFileWarningMessage: {
      phrase1: "Möchten Sie die Datei \"{{fileName}}\" wirklich löschen?",
      phrase2: "Dieser Vorgang kann nicht rückgängig gemacht werden."
    },
    approveDeletionOfFile: "Ja, Datei löschen"
  },
  rootPage: {},
  startPage: {
    fileManager: "Datei-Manager",
    fileCount: "Anzahl Dateien",
    totalSize: "Gesamtgröße",
    open: "Öffnen"
  }
};

export default {
  en,de
};
