export enum Language {GERMAN='de', ENGLISH='en'}

export function languageToFlagAbbreviationForSemanticUi(langAbbr: Language) {
	switch (langAbbr){
		case Language.ENGLISH:
			return "gb";
		default:
			return langAbbr
	}
}

export function isValidLanguage(langAbbr: string | Language): langAbbr is Language {
	return Object.values(Language).some(lang => lang === langAbbr)
}
