import * as React from 'react';
import {Dropdown, Image, Menu} from 'semantic-ui-react';
import './AppHeader.css';
import AppRoute from '../AppRoute';
import authService from "../../Core/AuthService";
import {NavLink} from "react-router-dom";
import {WithTranslation, withTranslation} from "react-i18next";
import LanguageSelector from "../../Common/LanguageSelector";

import dummyAvatar from '../../../dummy-avatar.png';

interface AppHeaderProps {
    onToggleSideBar: Function;
    isMobile: boolean;
    onLogout: Function;
    routes: AppRoute[];
}

//TODO: Extract CSS
class AppHeader extends React.Component<AppHeaderProps & WithTranslation, {}> {

    constructor(props: AppHeaderProps & WithTranslation) {
        super(props);
        this.clickMenuBtn = this.clickMenuBtn.bind(this);
    }

    clickMenuBtn() {
        this.props.onToggleSideBar();
    }

    renderMobileItems() {
      const {t} = this.props;
      return (<>
          <Menu.Item position="left" icon="bars" content={t('app:header.menu')} link={true} onClick={this.clickMenuBtn} style={{paddingBottom: '0!important', paddingTop: '0!important'}}/>
        <Menu.Item><LanguageSelector /></Menu.Item>
        <Menu.Item position="right">
            <Dropdown
              trigger={<span><Image src={dummyAvatar} avatar={true}/> <strong>{authService.principal.name}</strong></span>}
              icon={null}
              pointing="top left">
              <Dropdown.Menu>
                <NavLink to={"/user"}>
                  <Dropdown.Item text={t('app:header.profile')} icon="user"/>
                </NavLink>
                <Dropdown.Divider />
                <Dropdown.Item text={t('app:header.signout')} icon="sign out" onClick={() => this.props.onLogout()}/>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
      </>);
    }

    renderDesktopItems() {
      const {t} = this.props;
      return (
          <>
            <Menu.Item><LanguageSelector /></Menu.Item>
            <Menu.Item position="right" style={{minWidth: '40%'}}>
              {/* <Input icon="search" placeholder="Search..." disabled={true} /> */}
            </Menu.Item>
            <Menu.Item position="right">
              <Dropdown
                  trigger={<span><Image src={dummyAvatar} avatar={true}/> <strong>{authService.principal.name}</strong></span>}
                  icon={null}
                  pointing="top left">
                  <Dropdown.Menu>
                    <NavLink to={"/user"}>
                        <Dropdown.Item text={t('app:header.profile')} icon="user"/>
                    </NavLink>
                          <Dropdown.Divider />
                    <Dropdown.Item text={t('app:header.signout')} icon="sign out" onClick={() => this.props.onLogout()}/>
                  </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </>
      )
    }

    render() {
        return (
            <Menu borderless={true} fluid={true} compact={true} size="small" className="app-header-menu">
              {this.props.isMobile ? this.renderMobileItems() : this.renderDesktopItems()}
            </Menu>
        );
    }
}
export default withTranslation()(AppHeader);
