const en = {
  systemInfo: {
    header: 'System Information',
    dbUpdateRecommended: 'Database update recommended'
  }
};

const de = {
  systemInfo: {
    header: 'System-Informationen',
    dbUpdateRecommended: 'Update der Datenbank empfohlen'
  }
};

export default {
  en,de
};