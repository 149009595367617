// group by namespace / module to be able to split translation file later

import dataManagement from './data-management';
import files from './files';
import settings from './settings';
import navigation from './navigation';
import userProfile from './user-profile';

import userManagement from './user-management';
import entityConfiguration from './entity-configuration';
import bucketConfiguration from './bucket-configuration';
import environmentSettings from './environment-settings';
import emailSettings from './email-settings';
import qualityGatesLog from './quality-gates-log';
import dataExport from './data-export'
import dataImport from './data-import'
import dataExcelExport from './data-excel-export'
import dataExcelImport from './data-excel-import'
import mainLayoutContainer from "./main-layout-container"

const en = {
  common: {
    i18n: {
      language: "Language",
      languageTypes: {
        de: "Deutsch",
        en: "English"
      }
    },
    pageSizeDropdown: {
      perPage: 'per page'
    },
    confirmModalButton: {
      defaultHeader: 'Confirm Action',
      defaultCancel: 'Cancel',
      defaultConfirm: 'Confirm'
    }
  },
  dataManagement: dataManagement.en,
  files: files.en,
  settings: settings.en,
  navigation: navigation.en,
  userProfile: userProfile.en,
  userManagement: userManagement.en,
  entityConfiguration: entityConfiguration.en,
  bucketConfiguration: bucketConfiguration.en,
  environmentSettings: environmentSettings.en,
  emailSettings: emailSettings.en,
  dataExport: dataExport.en,
  dataImport: dataImport.en,
  dataExcelExport: dataExcelExport.en,
  dataExcelImport: dataExcelImport.en,
  qualityGatesLog: qualityGatesLog.en,
  mainLayoutContainer: mainLayoutContainer.en,
  app: {
    header: {
      menu: "Menu",
      profile: 'Profile',
      signout: 'Sign out'
    },
  },
  auth: {
    connection_error: 'Server is not reachable',
    connection_error_retry: 'Retry',
    login: {
      login_failed: 'Login failed',
      login_form_email: 'Email',
      login_form_password: 'Password',
      login_form_submit: 'Sign in',
      link_forgot_password: 'Forgot your password?',
      link_login_with_id_provider: 'Sign in with Id-Provider',
      link_login_with_email: 'Sign in with email',
      link_sso_login: 'Sign in with',
      error: {
        unspecified: 'Unknown error',
        bad_credentials: 'Bad credentials',
        xsrf_error: 'XSRF Error, please reload this page.',
        internal_error: 'Internal error. Please try again later or contact your administrator.',
        request_error: 'Could not send login request',
        response_error: 'Could not reach server',
        account_locked: 'Your account is locked',
        oauth_error: 'Error while authenticating with external provider'
      }
    },
    password_recovery: {
      header: 'Reset password',
      instructions_start: 'Enter your email address and we\'ll send you a link to reset your password.',
      instructions_success: 'If your email address exists, you will receive an email with further instructions.',
      form_email: 'Email',
      form_submit: 'Reset Password',
      link_back: 'Back',
      error_header: 'Error',
      error_text: 'Could not transmit request to reset password.'
    },
    password_recovery_set: {
      header: 'Set a new password',
      form_password: 'New password',
      form_password_confirm: 'Confirm new password',
      form_submit: 'Reset password',
      success_header: 'Password saved',
      success_text: 'You now can return to login',
      link_login: 'Return to login',
      expired_header: 'Link expired',
      expired_text: 'Your password recovery link is expired.'
    }
  }
};

const de = {
  common: {
    i18n: {
      language: "Sprache",
      languageTypes: {
        de: "Deutsch",
        en: "English"
      }
    },
    pageSizeDropdown: {
      perPage: 'pro Seite'
    },
    confirmModalButton: {
      defaultHeader: 'Aktion bestätigen',
      defaultCancel: 'Abbrechen',
      defaultConfirm: 'Bestätigen'
    }
  },
  dataManagement: dataManagement.de,
  files: files.de,
  settings: settings.de,
  navigation: navigation.de,
  userProfile: userProfile.de,
  userManagement: userManagement.de,
  entityConfiguration: entityConfiguration.de,
  bucketConfiguration: bucketConfiguration.de,
  environmentSettings: environmentSettings.de,
  emailSettings: emailSettings.de,
  qualityGatesLog: qualityGatesLog.de,
  dataExport: dataExport.de,
  dataImport: dataImport.de,
  dataExcelExport: dataExcelExport.de,
  dataExcelImport: dataExcelImport.de,
  mainLayoutContainer: mainLayoutContainer.de,
  app: {
    header: {
      menu: "Menü",
      profile: 'Profil',
      signout: 'Abmelden'
    },
  },
  auth: {
    connection_error: 'Server nicht erreichbar.',
    connection_error_retry: 'Erneut versuchen',
    login: {
      login_failed: 'Anmeldung fehlgeschlagen',
      login_form_email: 'E-Mail',
      login_form_password: 'Passwort',
      login_form_submit: 'Anmelden',
      link_forgot_password: 'Passwort vergessen',
      link_login_with_id_provider: 'Mit ID-Provider anmelden',
      link_login_with_email: 'Mit E-Mail-Adresse anmelden',
      link_sso_login: 'Login mit',
      error: {
        unspecified: 'Unbekannter Fehler',
        bad_credentials: 'Ungültige Zugangsdaten',
        xsrf_error: 'XSRF Fehler, bitte laden Sie die Seite neu.',
        internal_error: 'Interner Fehler, bitte versuchen Sie es später erneut oder kontaktieren Sie den Administrator',
        request_error: 'Request konnte nicht gesendet werden',
        response_error: 'Server konnte nicht erreicht werden',
        account_locked: 'Ihr Benutzerkonto ist gesperrt',
        oauth_error: 'Fehler bei der Anmeldung über externen Provider'
      }
    },
    password_recovery: {
      header: 'Passwort zurücksetzen',
      instructions_start: 'Geben Sie Ihre E-Mail-Adresse an, um einen Link zum Zurücksetzen Ihres Passwortes zu erhalten.',
      instructions_success: 'Falls Sie eine gültige E-Mail-Adresse angegeben haben, erhalten Sie nun eine E-Mail mit weiteren Instruktionen.',
      form_email: 'E-Mail',
      form_submit: 'Passwort zurücksetzen',
      link_back: 'Zurück',
      error_header: 'Fehler',
      error_text: 'Anforderung für das Zurücksetzen des Passwortes konnte nicht übertragen werden.'
    },
    password_recovery_set: {
      header: 'Neues Passwort festlegen',
      form_password: 'Neues Passwort',
      form_password_confirm: 'Neues Passwort wiederholen',
      form_submit: 'Passwort zurücksetzen',
      success_header: 'Passwort gespeichert',
      success_text: 'Kehren Sie nun zum Login zurück.',
      link_login: 'Zum Login',
      expired_header: 'Link abgelaufen',
      expired_text: 'Der aufgerufene Link ist nicht mehr gültig.'
    }
  }
};

export default {
  en, de
};
